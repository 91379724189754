import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import articleOne from '../../images/blog/article-one.png';
import articleTwo from '../../images/blog/article-two.png';
import articleThree from '../../images/blog/article-three.png';
import articleFour from '../../images/blog/article-four.png';
import arrowRight from '../../images/blog/arrow-right.png';
import moment from 'moment';


function Latest({history, blogsArray}) {
  // console.log("blogsArray", blogsArray);
  
  const url = "https://api.trippincreatives.com";

  // const project = [
  //   {img: [{ pic: `${url}${c.attributes.image.data.attributes.url}`}]},
  //   {img: articleTwo },
  //   {img: articleThree},
  //   {img: articleFour},
  // ]

  return (
    <div className='latest'>
      <div className='container'>
        <h2>Latest Articles</h2>
      <div className='row no-gutters'>
      {blogsArray.map((c,i)=>{
        console.log(c.id)
        return(
        <div className='col-md-6 mb-5'>
          <p> Posted on {moment(c.attributes.publishedAt).format('Do MMM YY')}</p>
         
          {/* <img src={c.pic} alt="" className='blog-img'/> */}
          <img src={`${url}${c.attributes.image.data.attributes.url}`} alt="" className='blog-img'/>
          <h6>{c.attributes.title}</h6>
          <p>{c.attributes.description.slice(0, 120)}</p>
          <div style={{cursor:'pointer'}} onClick={()=> history.push(`/blog/${c.id}`)} className='d-flex align-items-center'><p  
          className='mr-3 mb-0 readTxt'>Read More</p>
          <img src={arrowRight} alt="" /></div>
          </div>
      )})}
      </div>  
      </div>
    </div>
  )
}

export default withRouter(Latest)