/* eslint-disable no-redeclare */
import React, { useEffect, useState } from 'react'
// import { Line } from 'react-chartjs-2';

import lineOne from '../../images/vendor/lineOne.png';
import lineTwo from '../../images/vendor/lineTwo.png';
import lineThree from '../../images/vendor/lineThree.png';
import lineFour from '../../images/vendor/lineFour.png';
import greenOne from '../../images/vendor/greenOne.png';
import greenTwo from '../../images/vendor/greenTwo.png';
import greenThree from '../../images/vendor/greenThree.png';
import greenFour from '../../images/vendor/greenFour.png';
import round from '../../images/vendor/round.png';
import calendar from '../../images/vendor/calendar.png';
import placImage from '../../images/appointment/placeholder.jpeg';
import Charts from './Charts';
import axios from 'axios';
import moment from 'moment';

const customers = [
  { name: 'Customer A Name', image: round },
  { name: 'Customer B Name', image: round },
  { name: 'Customer C Name', image: round },
  { name: 'Customer D Name', image: round },
  { name: 'Customer E Name', image: round },
]

const packageJSON = {
  "vrpackage": '360 Virtual Tours',
  "videopackage": 'Videography',
  "photographypackage": 'Photography',
  "copywritingpackage": 'Copywriting',
  "dronespackage": 'Aerial Drones',
  "streampackage": 'Live Stream',
  "funeralpackage": 'Funeral',
  "weddingpackage": 'Wedding',
  "birthdaypackage": 'Birthday',
  "potraitpackage": 'Portrait'
}
const monthsArray = [
  {
    "abbreviation": "Jan",
    "name": "January"
  },
  {
    "abbreviation": "Feb",
    "name": "February"
  },
  {
    "abbreviation": "Mar",
    "name": "March"
  },
  {
    "abbreviation": "Apr",
    "name": "April"
  },
  {
    "abbreviation": "May",
    "name": "May"
  },
  {
    "abbreviation": "Jun",
    "name": "June"
  },
  {
    "abbreviation": "Jul",
    "name": "July"
  },
  {
    "abbreviation": "Aug",
    "name": "August"
  },
  {
    "abbreviation": "Sep",
    "name": "September"
  },
  {
    "abbreviation": "Oct",
    "name": "October"
  },
  {
    "abbreviation": "Nov",
    "name": "November"
  },
  {
    "abbreviation": "Dec",
    "name": "December"
  }
]



function Home() {
  const [cuArray, setArray] = useState([]);

  const [allOrders, setAllOrders] = useState([]);

  const [totalEarned, setTotalEarned] = useState(0);

  const [gd, setGd] = useState([])


  const getUserData = async () => {
    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/users',
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };
    await axios(config)
      .then(function (response) {
        console.log("All User Data", response.data.filter(u => u.type == "customer"));
        setCustomerData(response.data.filter(u => u.type == "customer"))
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getOrders = async () => {
    const vendor = JSON.parse(localStorage.getItem('user'))
    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/orders?pagination[pageSize]=500&sort=createdAt%3Adesc',
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };

    axios(config)
      .then(function (response) {

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        }
        if (mm < 10) {
          mm = '0' + mm;
        }
        var today = yyyy + '-' + mm + '-' + dd;

        let b = response.data.data.filter(order => order.attributes.vendor_id === vendor.id && order.attributes.status === "Pending")
        console.log('b',b);
        setAllOrders(b);

        let graphData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        var sum = 0
        for (let i = 0; i < b.length; i++) {
          let d = new Date(b[i].attributes.createdAt);
          graphData[d.getMonth()] += 50
          sum += 50
        }
        setTotalEarned(sum);
        setGd(graphData)

        let a = response.data.data.filter(r => r.attributes.vendor_id === vendor.id && r.attributes.date >= today && r.attributes.status === "Pending")
        console.log("Fitered Packages", a);
        setArray(a)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [customerData, setCustomerData] = useState([])

  useEffect(() => {
    getUserData()
    getOrders()
  }, [])


  const customerProfilePicture = (id) => {
    try {
      for (let i = 0; i < customerData.length; i++) {
        if (customerData[i].id == id)
          return customerData[i].image.url
      }
      return "/uploads/Screenshot_2022_03_27_at_12_51_1_bb4fa43aef.png"
    }
    catch (e) {
      return "/uploads/Screenshot_2022_03_27_at_12_51_1_bb4fa43aef.png"

    }
  }

  const customerPhoneNumber = (id) => {
    try {
      for (let i = 0; i < customerData.length; i++) {
        if (customerData[i].id == id)
          return customerData[i].phone
      }
      return "No Phone Number Given"
    }
    catch (e) {
      return "No Phone Number Given"

    }
  }

  console.log('customerData', customerData)
  return (
    <div className='home'>
      <div className='container'>
        <div className='row no-gutters'>
          <div className='col-md-8'>
            <div className='row no-gutters'>
              <div className='col-md-6 mobile-margin'>
                <div className="col-md-11 projects">
                  <div>
                    <p>Projects</p>
                    <p className='desc'>Overview of this month</p>
                    <p className='number'>{allOrders.length}</p>
                  </div>
                  <div className='imagesLines'>
                    <img src={lineOne} alt="" />
                    <img src={lineTwo} alt="" />
                    <img src={lineThree} alt="" />
                    <img src={lineFour} alt="" />
                  </div>
                </div>
              </div>
              <div className='col-md-6 '>
                <div className="col-md-11 projects">
                  <div>
                    <p>Deposits</p>
                    <p className='desc'>Overview of this month</p>
                    <p className='number'>SGD {totalEarned.toFixed(2)}</p>
                  </div>
                  <div className='imagesLines'>
                    <img src={greenOne} alt="" />
                    <img src={greenTwo} alt="" />
                    <img src={greenThree} alt="" />
                    <img src={greenFour} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {gd.length > 0 && <Charts earned={totalEarned} graphData={gd} />}
          </div>
          <div className='col-md-4 upcoming'>
            <p>Upcoming Projects</p>
            <p className='desc'>Overview of  this month</p>
            <p className='number'>{cuArray.length}</p>
            <hr />
            {cuArray.map(m => (
              <div className='d-flex mt-4'>
                <img src={`https://api.trippincreatives.com${customerProfilePicture(m.attributes.customer_id)}`} alt="" className='round' />

                <div className='px-3 grid'>
                  <p className='customer'>{m.attributes?.fullname}</p>
                  <p className='customer' style={{ fontSize: 8 }}>EMAIL : {m.attributes?.email}</p>
                  <p className='customer' style={{ fontSize: 8 }}>PHONE : {customerPhoneNumber(m.attributes.customer_id)}</p>
                  <p className='desc'>{packageJSON[m.attributes?.service_name]}<br />({m.attributes?.package})</p>
                </div>
                <img src={calendar} alt="" className='calendar' />
                <p className='date'>{m.attributes.date.split("-")[2] + " " + monthsArray[Number(m.attributes.date.split("-")[1]) - 1].abbreviation + " " + m.attributes.date.split("-")[0]}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home