import React, { useRef, Fragment } from 'react';
import logo from '../images/logo.png';
import fbLogo from '../images/fblogo.png';
import instaLogo from '../images/instalogo.png';
import twitterLogo from '../images/twitterlogo.png';
import sendBtn from '../images/sendbtn.png';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

import { Alert } from 'react-bootstrap';
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';
import back from '../images/appointment/back.png'

function Footer() {
  // const alert = useAlert();
  const name = useRef();
  const message = useRef();
  const email = useRef();

  const contact = async () => {
    if (name.current.value === '' || email.current.value === '' || message.current.value === '') {
      return toast.error('All contact fields required')
    }
    try {
      const response = await axios.post(`https://api.trippincreatives.com/api/contacts`, {
        data: {

          name: name.current.value,
          message: message.current.value,
          email: email.current.value,
        }

      })
      console.log('response', response);
      toast.success('Message sent', {
        position: "top-right",
        autoClose: 2000,
        });
      message.current.value = '';
      email.current.value = '';
      name.current.value = '';
    }
    catch (e) {
      toast.error('Message Not Sent', e, {
        position: "top-right",
        autoClose: 2000,
        });
      message.current.value = '';
      email.current.value = '';
      name.current.value = '';
    }
    // <Fragment>
    //    alert.success("Let You  Contact Soon...")
    // </Fragment>

  }
  return (
    <footer>
      <div className='container' id="getintouch">
        <ToastContainer />
        <div className='row no-gutters'>
          <div className='col-md-5'>

            {/* <Link to='/'><img src={back} alt="" className='back' /></Link> */}
            <Link className="navbar-brand" to="/">
              <img className="logo" src={logo} alt="" />
            </Link>
            <p className='small'>Email</p>
            <p>contact@trippincreatives.com</p>
            <p className='small'>Address</p>
            <p>20 Kay Poh Rd, Singapore 248969</p>
            <p className='small'>Social</p>
            <div className='d-flex socials'>
           <a href='https://www.facebook.com/trippincreatives/'>  <img  src={fbLogo} alt="" />    </a>

           <a href=' https://www.instagram.com/trippincreatives/?hl=en'>  <img src={instaLogo} alt="" /> </a>

           <a href='https://twitter.com/trippincreats'>  <img src={twitterLogo} alt="" /></a>
           
            </div>
          </div>
          <div className='col-md-7'>
            <h2>Keep In touch</h2>
            <p className='mb-4'>One Stop Center for all your photography and videography requirments. The best creative professionals in Singapore are here!</p>
            <input ref={name} type="text" className='form-control' placeholder='Name' />
            <input ref={email} type="email" className='form-control' placeholder='Email' />
            <textarea ref={message} className="form-control" placeholder="Message" rows={7} ></textarea>
            <button className='btn' onClick={contact}>Send</button>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer