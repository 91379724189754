
import React,{ useState,useEffect} from 'react'
import ReactPlayer from 'react-player';
import play from '../../images/play.png';
import appointement from '../../images/appointment.png';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

function Hero(props) {
  
  const [user, setuser] = useState(false)

  const history = useHistory();
  let links = "";
  const videoLink = links.concat(props.userdata.VideoLink,"?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&disablekb=1&rel=0&showinfo=0&color=black&playsinline=1&enablejsapi=1&playlist=ciK48oilHaI");
 

  const authinticate = () => {
 
    if(user){
      console.log('auth',user);
     history.push("/appointment");
    }
   else{
     history.push("/login");
    //  toast.error("Please Login First...!")
    }
  }
  useEffect(() => {
    setuser(localStorage.getItem("isAuthenticated"))
  }, [])

  return (
    <section className="hero">
      <iframe className="video " src={videoLink} width="800px" height="360" frameborder="0" allowfullscreen></iframe>
    
      <div className='container'>
        <div className="text col-md-6">
          <h1>{props.userdata.Hero_Title}</h1>
          <p>{props.userdata.Hero_Desc}</p>
            <img 
            className ='button'
            onClick ={ () => authinticate()}
             src={appointement} alt="" />
          </div>
        </div>
    </section>
  )
}

export default Hero


