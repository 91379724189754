import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import profilePic from '../images/appointment/placeholder.jpeg';
import 'react-toastify/dist/ReactToastify.css';
import bookmark from '../images/profile/bookmark.png';
import lock from '../images/profile/lock.png';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; 
import CountryCodes from './CountryCodes'
import { Link } from 'react-router-dom'

const project = [
  { img: bookmark, heading: 'Item Name One' },
  { img: bookmark, heading: 'Item Name Two', },
  { img: bookmark, heading: 'Item Name Three', },
  { img: bookmark, heading: 'Item Name Four', },
  { img: bookmark, heading: 'Item Name Five', },
  { img: bookmark, heading: 'Item Name Six', },
]

function Profile({ history }) {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")))
  const [profile, setprofile] = useState({ fullname: "", email: "" })
  const [picture, setpicture] = useState("")
  const [countryCode, setcountryCode] = useState(CountryCodes)
  const [token, settoken] = useState("")
  const [phone, setPhone] = useState("")
  const [file, setFile] = useState();
  const [imageId, setimageId] = useState(0)
  const [fileName, setFileName] = useState("");

  const handleChange = e => {
    setprofile({ ...profile, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    settoken(localStorage.getItem("token"));


    const setData = async () => {
      // Getting data from the token
      try {
        if (user) {
          console.log('userer', user)
          setprofile({ fileName: user.fullname })
          setprofile({ email: user.email })
          // profile.fullname= user.fullname
          // profile.email= user.email
          setPhone('+' + user.phone);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setData()
  }, [])
  // console.log(user)
  // console.log(phone)
  const fetchData = async () => {
    // Getting profile picture
    setuser(JSON.parse(localStorage.getItem("user")))
    settoken(localStorage.getItem("token"))
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      };
      const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config)
      setpicture(response.data.image.formats.thumbnail.url)
      setimageId(response.data.image.id)
      console.log('fetch',response.data.image.formats.thumbnail.url)
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  console.log(profile);

  // const deleteImage = async () => {
  //   // Getting profile picture
  //   try {
  //     const response = await axios.delete(`https://api.trippincreatives.com/api/upload/files/${imageId}`)
  //     toast.success('Your Image is deleted');
  //     history.push('/')
  //   } catch (error) {
  //     toast.error(error.response.data.error.message);
  //   }
  // }
  const  [removePic, setremovePic] = useState(false);
  
  const deleteImage= async (e) =>{
    e.preventDefault();

var con = {
  method: 'delete',
  url: 'https://api.trippincreatives.com/api/upload/files/'+imageId,
  headers: { 
    'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
  }
};

axios(con)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  toast.success('Your Image is deleted');
window.location.reload(false);

})
.catch(function (error) {
  console.log(error);
  toast.error("Error");
});

  }


  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
 

const saveFile = (e) => {
  e.preventDefault();
  const files = e.target.files[0];
  setFile(e.target.files[0]);
     setFileName(e.target.files[0].name);
  if (!files) {
   toast.error('image is required');
   return false;
   }
   if (!files.name.match(/\.(jpg|jpeg|png)$/)) {
     toast.error('File does not support. You must use .png or .jpg!');
    //  window.location.reload(true);
   
    return false;
   }

  }
  useEffect(() => {
    if (file) {
      order2();
     
    }
  }, [fileName, file,removePic]);
  const order = async (e) => {
    e.preventDefault()
    let formData = new FormData();
    formData.append("files", file);
    // console.log(phone)
    try {
      if (file) {
        // If we have a file
        // const response = await axios.post('/upload',
        //  formData
        // )
        const responses = await axios.put(`https://api.trippincreatives.com/api/users/${user.id}`, {
          fullname: profile.fullname,
          email: profile.email,
          phone: phone,
          image: imageId
        }, config)
        localStorage.setItem('user', JSON.stringify(responses.data));
        console.log('order',responses);
        toast.success('Your Changes Are Saved');
        // window.location.reload(false);
        history.push('/')
      } else {
        //  If there is no image
        const responses = await axios.put(`https://api.trippincreatives.com/api/users/${user.id}`, {
          fullname: profile.fullname,
          email: profile.email,
          phone: phone
        }, config)
        localStorage.setItem('user', JSON.stringify(responses.data));
        console.log('order1',responses);
        toast.success('Your Changes Are Saved');
        // window.location.reload(false);
        history.push('/')
      }
    }
    catch (e) {
      toast.error(e.response.data.error.message)
    }
  }
  const order2 = async () => {
    let formData = new FormData();
    formData.append("files", file);
    console.log('file',file);
    try {
      if (file) {
        // If we have a file
        const response = await axios.post('https://api.trippincreatives.com/api/upload',
          formData
        )

        setimageId(response.data[0].id)
        const responses = await axios.put(`https://api.trippincreatives.com/api/users/${user.id}`, {
          fullname:profile.fullname,
          email:profile.email,
          phone:phone,
          image: response.data[0].id
        }, config)
        localStorage.setItem('user', JSON.stringify(responses.data));
        console.log('abc',responses);
        fetchData();
        // toast.success('Profile picture uploaded');
        window.location.reload(false);
        history.push('/')
      } else {
        //  If there is no image
        const responses = await axios.put(`https://api.trippincreatives.com/api/users/${user.id}`, {
          fullname: profile.fullname,
          email: profile.email,
          phone: phone
        }, config)
        localStorage.setItem('user', JSON.stringify(responses.data));
        console.log('xyz',responses);
        // toast.success('Your Changes Are Saved');
        window.location.reload(false);
        history.push('/')
      }
    }
    catch (e) {
      toast.error("Error")
    }
  }
  return (
    <div className='profile row no-gutters'>
      <Navbar saveFile={file}/>
      <ToastContainer />
      <div className='container'>
        <h1>My Profile</h1>
        <div className='row no-gutters'>
          <div className='col-md-4 mr-3'>
            <div className='profile-content'>
              <p className='my-profile'>My Profile</p>
              <hr />
              <img src={picture ? `https://api.trippincreatives.com${picture && picture}` : profilePic} alt="" className='profile' />
              <p className='mt-3'>{user && user.fullname}</p>
             <input type='file' accept="image/*" onChange={saveFile} name='selectedfile' className='btn change' />
              <button className='btn remove' onClick={deleteImage}>Remove Photo</button>
            </div>
            {/* <div className='profile-content mt-3'>
              <p className='my-profile'>My Saved Items</p>
              <hr />
              {project.map((c, i) => (
                <div className='d-flex mx-3 mb-3 align-items-center justify-content-between'>
                  <p className='mb-0'>{c.heading}</p>
                  <img src={c.img} alt="" className='bookmark' />
                </div>
              ))}
            </div> */}
          </div>
          <div className='col-md-7'>
            <div className='form-submission'>
              <p className='my-profile'>Edit Profile</p>
              <hr />
              <form>
                <label>Full Name</label>
                <input type="text" placeholder="Jessica" name="fullname" defaultValue={user && user.fullname} onChange={handleChange} className='form-control' />
                <label>Email Address</label>
                <input type="email" placeholder="Jessicakhoo@gmail.com" name="email" defaultValue={user && user.email} onChange={handleChange} className='form-control mr-4' />
                <label className='mb-3'>Contact No.</label>
                <div className='d-flex'>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={user && '+' + user.phone}
                    onChange={setPhone} />
                  {/* <input type="number" placeholder="Contact No" name="phone" defaultValue={user && user.phone}  onChange={handleChange} className='form-control' autoComplete="off" /> */}
                </div>
                <div className='bottom'>
                  <div className='buttons-div'>
                    <div className='d-flex align-items-center'>
                      <img src={lock} id='lock' alt="" />
                      <Link to="/change-password/customer"><p className='pass'>Change Password</p></Link>
                    </div>
                    <button className='btn btn-success' onClick={order}>Save Changes</button>
                    <button className='btn btn-cancel' onClick={() => history.push('/')}>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Profile;