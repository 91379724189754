/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from 'axios';
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import demo from '../images/portfolio/demo.png';
import demo2 from '../images/portfolio/demo2.png';
import demo3 from '../images/portfolio/demo3.png';
import star from '../images/portfolio/star.png';
import vector from '../images/portfolio/vector.png';
import ReadMore from '../components/landing/ReadMore';
import Photography from '../components/landing/Photography';
import place from '../images/appointment/placeholder.jpeg'
import Dropdown from 'react-dropdown';
import { data } from 'autoprefixer';
import Reviews from '../components/details/Reviews';


function Portfolio({ history }) {
  console.log("history", history);
  const options = [
    { value: 'All', label: 'All Categories' },
    { value: 'Photography', label: 'Photography', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Videography', label: 'Videography', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Live Stream', label: 'Live Stream', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Aerial Drone', label: 'Aerial Drone', color: '#000000', fontWeight: 'medium-bold' },
    { value: '360 Virtual Tour', label: '360 Virtual Tour', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Copywriting', label: 'Copywriting', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Wedding', label: 'Wedding', color: '#000000', fontWeight: 'medium-bold', width: '50%' },
    { value: 'Funeral', label: 'Funeral', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Birthday', label: 'Birthday', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Portrait', label: 'Portrait', color: '#000000', fontWeight: 'medium-bold' },
  ];

  const filterOptions = [
    { value: 'popular', label: 'Popular', className: 'dropdown', color: '#000000', fontWeight: 'bold', fontStyle: 'Montserrat' },
    { value: 'popular', label: 'Popular', className: 'dropdown', color: '#000000', fontWeight: 'bold', fontStyle: 'Montserrat' },
    { value: 'popular', label: 'Popular', className: 'dropdown', color: '#000000', fontWeight: 'bold', fontStyle: 'Montserrat' },

  ]

  const [state, setState] = useState("");

  const [num, setNum] = useState(0)
  const [vendor, setVendor] = useState([])
  const [vendorToShow, setVendorToShow] = useState([])
  const [datas, setdatas] = useState([])
  const [carouselpic, setcarouselpic] = useState([]);

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [selectedOptions, setSelectedOptions] = useState(filterOptions[0]);

  const [reviewData, setReviewData] = useState([]);

  const [search, setSearch] = useState('');

  const getAllReviews = async () => {

    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/vendor-reviews?populate=%2A&pagination[pageSize]=500',
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };

    await axios(config)
      .then(function (response) {
        console.log("Reviews", response.data.data);
        setReviewData(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  console.log('reviewData', reviewData)
  useEffect(() => {
    if (selectedOption.label === "All Categories") {
      setVendorToShow(vendor)
    }
    else {
      setVendorToShow(vendor.filter(vendor => vendor.services && vendor.services.includes(selectedOption.label)))
    }
  }, [selectedOption])


  const getVendor = async () => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users?populate=%2A&pagination[pageSize]=500');
      console.log(response.data)
      const sortArray = response.data.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      })
      setVendor(sortArray)
      setVendorToShow(sortArray)
    }
    catch (e) {
      console.log(e)
    }
  }
console.log('vendor',vendor)
  useEffect(() => {
    if (!search) {
      setVendorToShow(vendor)
    }
    else {
      let a = []
      for (let i = 0; i < vendor.length; i++) {
        if (vendor[i].fullname) {
          console.log(vendor[i].fullname.toLowerCase().includes(search.toLowerCase()))
          if (vendor[i].fullname.toLowerCase().includes(search.toLowerCase())) {
            a.push(vendor[i])
          }
        }
      }
      //console.log("Filtered Vendors", a)
      setVendorToShow(a)
    }
  }
    , [search])
  const getAverageRatingAndNumberOfReviews = (userID, rating) => {
    // console.log('userID',userID)
    // console.log('rating',rating)
    var count = 0
    var sum = 0
    var review = []
    for (var i = 0; i < rating.length; i++) {
      // console.log('useridddddd', rating[i])
      if (rating[i].attributes.user.data.id === userID) {
        console.log(userID === rating[i].attributes.user.data.id,'????????????')
        count++;
        sum += rating[i].attributes.Rating
        review.push(rating[i])
      }
    }
    console.log('reviewsss',review)
    return { count: count, avg: sum === 0 || count === 0 ? 0 : Math.round(sum / count), review: review }
  }

  async function getData() {

    try {
      const res = await axios.get(
        "https://api.trippincreatives.com/api/home?populate=%2A")
      //console.log("res", res);
      setdatas(res.data.data.attributes);
      setcarouselpic(res.data.data.attributes.LastCarousel.data);


    } catch (e) {
      console.log("some error occured while listing")
    }

  }

  const getPrice = (s) => {
    //console.log(s.id)
    if (s.copywritingpackage != null && s.copywritingpackage.length > 0) {
      //console.log(s.copywritingpackage)
      return s.copywritingpackage[0].priceA
    }
    if (s.dronespackage != null && s.dronespackage.length > 0) {
      //console.log(s.dronespackage)
      return s.dronespackage[0].priceA
    }
    if (s.photographypackage != null && s.photographypackage.length > 0) {
      //console.log(s.photographypackage)
      return s.photographypackage[0].priceA
    }
    if (s.streampackage != null && s.streampackage.length > 0) {
      //console.log(s.streampackage)
      return s.streampackage[0].priceA
    }
    if (s.videopackage != null && s.videopackage.length > 0) {
      //console.log(s.videopackage)
      return s.videopackage[0].priceA
    }
    if (s.vrpackage != null && s.vrpackage.length > 0) {
      //console.log(s.vrpackage)
      return s.vrpackage[0].priceA
    }
    return -1;
  }
  useEffect(() => {
    getVendor()
    getData()
    getAllReviews()
  }, [])
  // console.log("UserData",userdata)
  const handleChange = (selectedOption) => {
    console.log('handleChange', selectedOption);
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,

      fontStyle: '$monstert',
      fontWeight: 'normal',
      color: 'black',
      background: 'white'
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsl(147, 50%, 47%, 0.5)'
        : isSelected
          ? 'hsl(147, 50%, 47%, 5)'
          : undefined
    })
  }

  const colorStyles = {
    menuList: styles => ({
      ...styles,
      fontStyle: '$monstert',
      fontWeight: 'normal',
      color: 'black',
      background: 'white',
      opacity: '1'
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsl(147, 50%, 47%, 0.5)'
        : isSelected
          ? 'hsl(147, 50%, 47%, 1)'
          : 'white'

    }),
    menu: (styles) => ({
      ...styles,
      border: "1px solid",
      width: "15rem",
      minWidth: "100%"
    }),
  }
  return (
    <div className='row no-gutters portfolio'>
      <Navbar />
      <div className='col-10 m-auto  sub-portfolio'>
        <div className='container'>
          <h1>Portfolios</h1>
          <div className='filter-div'>
            <input placeholder='Search' value={search} onChange={(e) => { setSearch(e.target.value) }} className='form-control' type="search" />
            <Select
              className='dropdown'
              classNamePrefix
              styles={colorStyles}
              autosize={true}
              clearable={false}
              simpleValue
              options={options}
              onChange={(e) => {
                setSelectedOption(e)
                console.log(e)
              }
              }
              value={selectedOption}
            />

          </div>

          <div className='cards-div'>
            {vendorToShow.map(ar => {
              console.log('ar', ar.id, ar.fullname)
              return (
                (ar.type !== 'customer' && ar.fullname != null) && <div onClick={() => history.push({ pathname: '/details/' + ar.id, state: { ar, review: getAverageRatingAndNumberOfReviews(ar.id, reviewData)["review"] } })} className='box'>
                  {ar.image ? <img src={`https://api.trippincreatives.com${ar.image.url}`} className="person" /> : <img src={place} className="person" />}
                  <p>{ar.fullname}</p>
                  <span>{selectedOption.label != "All Categories" && selectedOption.label}</span>
                  <div className='review-div'>
                    <div>
                      {Array(getAverageRatingAndNumberOfReviews(ar.id, reviewData)["avg"]).fill(4).map((_, i) => <img src={star} />)}
                    </div>
                    {console.log("Review of", ar.id, getAverageRatingAndNumberOfReviews(ar.id, reviewData)["avg"])}
                    <span>{getAverageRatingAndNumberOfReviews(ar.id, reviewData)["count"]} Reviews</span>
                  </div>
                  <div className='price-div'>
                    {getPrice(ar) != -1 ? (<span>From ${getPrice(ar)}/hour</span>) : (<span>Price Not Available</span>)}
                    {/* <img height="1%" src={vector} /> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <ReadMore userdata={datas} />
      <Photography carouselpic={carouselpic} />
      {/* <Reviews reviewData={reviewData} /> */}
      <Footer />
    </div>
  )
}

export default Portfolio;