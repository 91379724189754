import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import flower from '../images/portfolio/flower.png';
import ReadMore from '../components/landing/ReadMore';
import Photography from '../components/landing/Photography';
import axios from 'axios';

function Services() {
  const array = [
    { name: 'Videography', },
    { name: 'Photography', },
    { name: 'Aerial Drones', },
    { name: 'Live Stream', },
    { name: '360 Virtual Tours' },
  ];

  let { name } = useParams();
  const location = useLocation();
  const data = location.state.id;
  console.log("data", data);
  // setNum(data);
  const [num, setNum] = useState(data)

  const [userData, setUserData] = useState([]);
  const [aerialcollage, setaerialcollage] = useState([]);
  const [photocollage, setphotocollage] = useState([]);
  const [videocollage, setvideocollage] = useState([]);
  const [livestreamcollage, setlivestreamcollage] = useState([]);
  const [virtualcollage, setvirtualcollage] = useState([]);
  //const [copywritingserviceurl, setcopywritingserviceurl] = useState([]);
  const url = "https://api.trippincreatives.com";

  const array2 = [
    { images: [{ pic: `${url}${aerialcollage.url}`, class: 'gallery__item--1' }] },
    { images: [{ pic: `${url}${photocollage.url}`, class: 'gallery__item--1' }] },
    { images: [{ pic: `${url}${videocollage.url}`, class: 'gallery__item--1' }] },
    { images: [{ pic: `${url}${livestreamcollage.url}`, class: 'gallery__item--1' }] },
    { images: [{ pic: `${url}${virtualcollage.url}`, class: 'gallery__item--1' }] }]
  // { images: [{ pic: `${url}${photocollage.url}`, class: 'gallery__item--1' }] }]
  //gallery
  const [gallerypics, setgallerypics] = useState([]);

  async function getData() {

    try {
      const res = await axios.get(
        "https://api.trippincreatives.com/api/servicespage?populate=%2A")
      // console.log("res", res);
      setUserData(res.data.data.attributes);
      setaerialcollage(res.data.data.attributes.AerialCollage.data.attributes);
      setphotocollage(res.data.data.attributes.PhotograhpyCollage.data.attributes);
      setvideocollage(res.data.data.attributes.VideographyCollage.data.attributes);
      setlivestreamcollage(res.data.data.attributes.LivestreamCollage.data.attributes);
      setvirtualcollage(res.data.data.attributes.VirtualTourCollage.data.attributes);
      // setcopywritingserviceurl(res.data.data.attributes.CopywritingService.data.attributes);
      setgallerypics(res.data.data.attributes.Gallery.data);

    } catch (e) {
      console.log("some error occured while listing")
    }

  }
  useEffect(() => { getData() }, []);
  console.log("servicePage", aerialcollage)

  return (
    <div className='row no-gutters service-page'>
      <Navbar />
      <div className='col-10 m-auto  sub-service'>
        <div className='container'>
          <h1 style={{    display: 'flex', justifyContent: 'center'}}>Services</h1>
          <p>{userData.Serviceherotext}</p>

          <div className='view-div'>
             
            <ul>
              {array.map((ar, index) => (
                <li className={`${index === num && 'active'}`} onClick={() => setNum(index)}>{ar.name}</li>
              ))}
            </ul>


            <div className='images-div'>
              {array2.map((im, index) => (
                index === num && im.images.map(image =>
                  index  === 1 ? (
                    <figure className={image.class}>
                      <img   src={image.pic} alt="service_image" />
                    </figure>
                    
                  ) : (
                    
                      <div className="content">

                        <iframe width="100%" height="100%" className="iframe "
                          src="https://www.youtube.com//embed/ciK48oilHaI?controls=0  " title="YouTube video player"
                          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                        </iframe>

                        <iframe width="100%" height="100%" className="iframe "
                          src="https://www.youtube.com/embed/Khdkfp1tQCY?controls=0  " title="YouTube video player"
                          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                        </iframe>

                        <iframe width="100%" height="100%" className="iframe"
                          src="https://www.youtube.com/embed/QZI2u1x7MmY?controls=0 " title="YouTube video player"
                          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                        </iframe>

                        <iframe width="100%" height="100%" className="iframe "
                          src="https://www.youtube.com/embed/q0D_8kqwPE4?controls=0" title="YouTube video player"
                          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                        </iframe>
                      </div>
                 

                  )
                )
              ))}

              <img src={flower} alt="" className='flower' />
            </div>
          </div>
        </div>
      </div>
      {/* <Promotion /> */}
      <ReadMore userdata={userData} />
      <Photography carouselpic={gallerypics} />
      <Footer />
    </div>
  )
}

export default Services