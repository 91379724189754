import React from 'react'

import logo from '../../images/logo.png';
import next from '../../images/appointment/next.png';
import back from '../../images/appointment/back.png';
import pencil from '../../images/appointment/pencil.png';
import payment from '../../images/appointment/made-payment.png';
import dbsbank from '../../images/appointment/dbsbank.png';
import paynow from '../../images/appointment/paynow.png';

function StepEight() {
  return (
    <div className='step-eight'>
      <div className='container header'>
        <img src={back} alt="" className='back' />  
        <img src={logo} alt="" />
        <h2>Make Payment</h2>
        </div>
        <div className='packages'> 
          <div className='container'>  
            <div className='row no-gutters'>
            <div className='col-md-4 total'>
                <h2>Total Payable</h2> 
                <p className='package'>Total Amount</p>
                <div className='d-flex justify-content-between heading mt-4'>
                  <p>Service</p>
                  <p>Qty</p>
                  <p>Amt</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <p>Photography</p>
                  <p>1</p>
                  <p>600</p>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                  <p className='heading'>Incl. GST</p>
                  <p>80.00</p>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                  <p  className='heading'>Total</p>
                  <p>800.00</p>
                </div>
              </div>
              <div className='col-md-4'>
                <h2>Bank Transfer</h2>
                <div className='d-flex'>
                <img src={dbsbank} alt="" className="dbsbank" />
                <div>
                  <p className='package'>DBS Bank</p>
                  <div className='d-flex'><p className='bold'>Ref No.</p><p>12345678</p></div>
                  <div className='d-flex'><p className='bold'>Acc No.</p><p>028-12234-01</p></div>
                  <div className='d-flex'><p className='bold'>Amt $</p><p>800.00</p></div>
                  
                </div>
                </div>
              </div>
              <div className='col-md-4'>
                <h2>Paynow</h2>
                <img src={paynow} alt="" />
                <img src={payment} alt="" className='payment' />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default StepEight