/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import logo from '../../images/logo.png';
import next from '../../images/appointment/next.png';
import demo from '../../images/portfolio/demo.png';
import demo2 from '../../images/portfolio/demo2.png';
import demo3 from '../../images/portfolio/demo3.png';
import star from '../../images/portfolio/star.png';
import vector from '../../images/portfolio/vector.png';
import back from '../../images/appointment/back.png';
import axios from 'axios';
import place from '../../images/appointment/placeholder.jpeg';

function StepThree({ changeStatus, handleCustomer, user, completed, handleVendor }) {

  const [vendor, setVendor] = useState([]);
  const [reviewData, setReviewData] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState(user.vendor_id);

  const packageJSON = {
    "vrpackage": '360 Virtual Tours',
    "videopackage": 'Videography',
    "photographypackage": 'Photography',
    "copywritingpackage": 'Copywriting',
    "dronespackage": 'Aerial Drones',
    "streampackage": 'Live Stream',
    "funeralpackage": 'Funeral',
    "weddingpackage": 'Wedding',
    "birthdaypackage": 'Birthday',
    "potraitpackage": 'Portrait'
  }

  const getVendor = async () => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users');
      console.log("All Vendor Data", response.data)
      let v = response.data.filter(u => u.type === 'vendor' && u.services.includes(packageJSON[user.service_name]))
      v.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
      setVendor(v)
    }
    catch (e) {
      console.log(e)
    }
  }
  const getAllReviews = async () => {

    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/vendor-reviews?populate=%2A',
      headers: {}
    };

    await axios(config)
      .then(function (response) {
        //console.log("Reviews",response.data.data);
        setReviewData(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  useEffect(() => {
    getVendor()
    getAllReviews()
  }, [])

  const getAverageRatingAndNumberOfReviews = (r, userID) => {
    var count = 0
    var sum = 0
    var review = []
    for (var i = 0; i < r.length; i++) {
      if (r[i].attributes.user.data.id == userID) {
        count++;
        sum += r[i].attributes.Rating
        review.push(r[i])
      }
    }
    return { count: count, avg: sum == 0 || count == 0 ? 0 : Math.round(sum / count), review: review }
  }

  const getPrice = (s) => {
    //console.log(s.id)
    if (s.copywritingpackage != null && s.copywritingpackage.length > 0) {
      //console.log(s.copywritingpackage)
      return s.copywritingpackage[0].priceA
    }
    if (s.dronespackage != null && s.dronespackage.length > 0) {
      //console.log(s.dronespackage)
      return s.dronespackage[0].priceA
    }
    if (s.photographypackage != null && s.photographypackage.length > 0) {
      //console.log(s.photographypackage)
      return s.photographypackage[0].priceA
    }
    if (s.streampackage != null && s.streampackage.length > 0) {
      //console.log(s.streampackage)
      return s.streampackage[0].priceA
    }
    if (s.videopackage != null && s.videopackage.length > 0) {
      //console.log(s.videopackage)
      return s.videopackage[0].priceA
    }
    if (s.vrpackage != null && s.vrpackage.length > 0) {
      //console.log(s.vrpackage)
      return s.vrpackage[0].priceA
    }
    if (s.weddingpackage != null && s.weddingpackage.length > 0) {
      // console.log('/////******',s.weddingpackage)
      return s.weddingpackage[0].priceA
    }
    if (s.funeralpackage != null && s.funeralpackage.length > 0) {
      // console.log('/////******',s.​funeralpackage)
      return s.funeralpackage[0].priceA
    }
    if (s.birthdaypackage != null && s.birthdaypackage.length > 0) {
      // console.log('/////******',s.birthdaypackage)
      return s.birthdaypackage[0].priceA
    }
    if (s.potraitpackage != null && s.potraitpackage.length > 0) {
      console.log('/////******', s.potraitpackage)
      return s.potraitpackage[0].priceA
    }
    return -1;
  }

  return (
    <div className='step-three'>
      <div className='completed' style={{ borderTop: '8px solid #18BB78', width: `${completed}%`, marginTop: 0 }}> </div>
      <div className='container'>
        <img onClick={() => changeStatus(1)} src={back} alt="" className='back' />
        <img src={logo} alt="" />
        <h2>Who would you like to engage?</h2>
        <div className='cards-div'>
          {vendor.map((ar, index) => {
            // console.log(ar.fullname, ar.id)
            return (
              <div className='box' key={index}>
                {/* {ar.image ? <img src={`${process.env.REACT_APP_API_UPLOADS}${ar.image.url}`} className="person" /> : <img src={ar.image} className="person" />} */}
                {ar.image ? <img src={`https://api.trippincreatives.com${ar.image.url}`} className="person" /> : <img src={demo} className="person" />}
                <p className='bold' style={{ textTransform: "capitalize" }}>{ar.fullname}</p>
                <div className='review-div'>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div>
                      {Array(getAverageRatingAndNumberOfReviews(ar.id, reviewData)["avg"]).fill(4).map((_, i) => <img src={star} key={i} />)}
                    </div>
                    <span className='review'>{getAverageRatingAndNumberOfReviews(ar.id, reviewData)["avg"]}.0</span>
                  </div>
                </div>
                <div className='price-div'>
                  {getPrice(ar) != -1 ? (<span>From ${getPrice(ar)}/per hour</span>) : (<span>Price Not Available</span>)}
                </div>
                <button onClick={() => { handleCustomer(ar.id, ar.fullname); setSelectedVendor(ar.id); }} className={selectedVendor == ar.id ? 'btn green' : 'btn'}>{selectedVendor == ar.id ? 'Selected' : 'Select'}</button>
              </div>
            )
          })}
        </div>
        {/* <button onClick={() => changeStatus(4)} className='btn nxt'>Next</button> */}
      </div>
    </div>
  )
}

export default StepThree