/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import star from '../../images/portfolio/star.png';
import dropdown from '../../images/portfolio/dropdown.png';
import reviewOne from '../../images/portfolio/review-one.png';
import unknown from '../../images/unknown.png';
import reviewTwo from '../../images/portfolio/review-two.png';
import reviewThree from '../../images/portfolio/review-three.png';
import monica from '../../images/portfolio/monica.png';
import contactBtn from '../../images/portfolio/contact-btn.png';
import tape from '../../images/portfolio/tape.png';

function Reviews({ reviews, details, }) {

console.log('reviews',reviews);
console.log('details',details);
  const [overallRating, setOverallRating] = useState(0)


  useEffect(() => {
    console.log("reviews from individual page", reviews)
    console.log("details from individual page", details)

    let overRating = 0;

    reviews.map((review) => {
      overRating += review.attributes.Rating
    })
    setOverallRating(overRating == 0 || reviews.length == 0 ? 0 : Math.round(overRating / reviews.length))
  }
    , [])

  return (
    <div className='reviews'>
      <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
          <div>
            <h1>{reviews.length} Reviews</h1>
            {reviews.length > 0 && <div className='d-flex align-items-end'>
              {Array(overallRating).fill().map((_, i) => (<img src={star} key={i} alt='star' />))}
              <p className='rating'>{overallRating}.0</p>
            </div>}
          </div>
          {/* {reviews.length > 0 && <div className="dropdown">
            <img src={dropdown} alt="" />
            <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Most Relevant &nbsp; &#709;
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#">Recent</a>
              <a className="dropdown-item" href="#">Good action</a>
              <a className="dropdown-item" href="#">Excellent</a>
            </div>
          </div>} */}
        </div>
        <div className='row no-gutters my-5'>
          <div className='col-md-6'>
            {reviews.map((item, index) => {
              return (<><div key={index} className='d-flex'>
                <img src={unknown} alt="" className='review-img' />
                <div>
                  <p className='name'>{item.attributes.Name}</p>
                  <div className='d-flex ml-25' >
                    <div className='d-flex'>
                      <img src={star} alt="" className='star' />
                      <p className='rating'>{item.attributes.Rating}.0</p>
                    </div>
                    <p className='descTime d-flex '>{moment(item.attributes.createdAt).format('DD-MM-YYYY')}</p>
                  </div>
                  <p className='desc'>{item.attributes.Description}</p>
                </div>

              </div>
                <hr />
              </>


              )
            }
            )
            }

          </div>
          <div className='col-md-5 offset-md-1'>
            <div className='bg'>
              <div className='white-bg'>
                <img src={tape} alt="" className='tape' />
                {details.image && <img src={`https://api.trippincreatives.com${details.image.url}`} alt="" className='person' />}
                <p>{details.fullname}</p>
              </div>
              {/* <img src={contactBtn} alt="" className='contact' /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews