import React from 'react'

import logo from '../../images/logo.png';
import next from '../../images/appointment/next.png';
import back from '../../images/appointment/back.png';
import portrait from '../../images/appointment/portrait.png';
import baby from '../../images/appointment/baby.png';
import babyShower from '../../images/appointment/baby-shower.png';
import birthday from '../../images/appointment/birthday.png';
import couple from '../../images/appointment/couple.png';
import family from '../../images/appointment/family.png';
import funeral from '../../images/appointment/funeral.png';
import graduation from '../../images/appointment/graduation.png';
import pregnant from '../../images/appointment/pregnant.png';
import proposal from '../../images/appointment/proposal.png';
import wedding from '../../images/appointment/wedding.png';
import others from '../../images/appointment/others.png';
import check from '../../images/appointment/check.png';

const project = [
  {img: portrait,name:'Portrait'},
  {img: baby ,name:'Baby'},
  {img: birthday,name:'Birthday'},
  {img: babyShower,name:'Baby Shower'},
  {img: couple,name:'Couple'},
  {img: family ,name:'Family'},
  {img: funeral,name:'Funeral'},
  {img: graduation,name:'Graduation'},
  {img: pregnant,name:'Pregnant'},
  {img: proposal ,name:'Proposal'},
  {img: wedding,name:'Wedding'},
  {img: others,name:'Others'},
]
console.table('project',project)

function StepTwo({changeStatus,handleCat,user,completed}) {
  return (
    <div className='step-two'> 
    <div className='completed' style={{borderTop:'8px solid #18BB78',width:`${completed}%`,marginTop:0}}> </div> 
    <div className='container'>
    <img onClick={()=> changeStatus(1)} src={back} alt="" className='back' />  
      <img src={logo} alt="" />
      <h2>Please select category</h2>
      <div className='box-list'>
      {project.map((c,i)=>(
      <div onClick={()=>handleCat(c.name)} className={user.category == c.name ? 'box green' : 'box'}>
        {user.category == c.name ? <img src={check} alt="" className='check' />: ''}
        <img src={c.img} alt="" />
        <p>{c.name}</p>
      </div>
      ))}
    </div>
    <button onClick={()=> changeStatus(3)} className='btn'>Next</button>
    </div>
    </div>
  )
}

export default StepTwo