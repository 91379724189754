import React, { useEffect, useState } from 'react'
import portfolioOne from '../../images/portfolio/portfolio-one.png';
import portfolioTwo from '../../images/portfolio/portfolio-two.png';
import portfolioThree from '../../images/portfolio/portfolio-three.png';
import portfolioFour from '../../images/portfolio/portfolio-four.png';
import portfolioFive from '../../images/portfolio/portfolio-five.png';
import portfolioSix from '../../images/portfolio/portfolio-six.png';
import axios from 'axios';
import Select from 'react-select';


function Portfolio({ vendor }) {
  const [images, setImages] = useState([])
  const [catee, setCat] = useState('All')

  const options = [
    { value: 'All', label: 'All', },
    { value: 'Photography', label: 'Photography', color: '#000000', fontWeight: 'medium-bold', width: '50%' },
    { value: 'Videography', label: 'Videography', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Live Stream', label: 'Live Stream', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Aerial Drone', label: 'Aerial Drone', color: '#000000', fontWeight: 'medium-bold' },
    { value: '360 Virtual Tours', label: '360 Virtual Tour', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Copywriting', label: 'Copywriting', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Wedding', label: 'Wedding', color: '#000000', fontWeight: 'medium-bold', width: '50%' },
    { value: 'Funeral', label: 'Funeral', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Birthday', label: 'Birthday', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'Potrait', label: 'Potrait', color: '#000000', fontWeight: 'medium-bold' },

  ];


  const getSerivec = async () => {

    var numberOfPages=1;

    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/services',
      headers: { }
    };
    
    axios(config)
    .then(function (response) {
      numberOfPages=response.data.meta.pagination.pageCount
    })
    .catch(function (error) {
      console.log(error);
    });

    var dataOfPortfolio=[]

    for(let i=1;i<=numberOfPages;i++)
    {
      const config2 = {
        method: 'get',
        url: 'https://api.trippincreatives.com/api/services?populate=%2A&pagination[page]='+i,
        headers: {}
      };
  
      await axios(config2)
        .then(function (response) {
         
          console.log('response',response);
          console.log(response.data.data.filter(u => u.attributes.userid === vendor.id),i)
          dataOfPortfolio=dataOfPortfolio.concat(response.data.data.filter(u => u.attributes.userid === vendor.id))
         
        
        })
        .catch(function (error) {
          console.log(error);
        });

    }

   setImages(dataOfPortfolio)

  }
  
  useEffect(() => {
    getSerivec()
  }, [vendor])
  console.log('images', images)

  const colorStyles = {
    menuList: styles => ({
      ...styles,
      fontStyle: '$monstert',
      fontWeight: 'normal',
      color: 'black',
      background: 'white',
      opacity: '1',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsl(147, 50%, 47%, 0.5)'
        : isSelected
          ? 'hsl(147, 50%, 47%, 1)'
          : 'white',
      zIndex: 1
    }),
    menu: (styles) => ({
      ...styles,
      border: "1px solid",
      width: "15vw",
      top: '2rem',
      zIndex: 100

    }),
    control: (styles) => ({
      ...styles,
      background: "#262323",
      color: 'white',

    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'white',
    })
  }
  console.log('catee', catee);

  return (
    <div className='portfolio'>
      <div className='container'>
        <div className='join '>
          <h1>My Portfolio</h1>
          <div className='select-option'>
            <Select
              className='dropdown-portfolio'
              styles={colorStyles}
              options={options}
              onChange={(e) => {
                setCat(e.value)
                console.log(e)
              }
              }
              value={options.filter(({ value }) => value === catee)}

            />



          </div>
        </div>

        <div className='portfolio-images'>
          {images.length > 0 && images.map((image, index) => {
            if (catee === 'All') {
              return (<img
                src={`https://api.trippincreatives.com${image.attributes.images.data[0].attributes.url}`}
                alt=""
                key={index}
                width={150}
                style={{ margin: 10 }}
              />)
            }
            else if (image.attributes.service_name == catee) {
              return (
                <img
                  src={`https://api.trippincreatives.com${image.attributes.images.data[0].attributes.url}`}
                  alt=""
                  key={index}
                  width={150}
                  style={{ margin: 10 }}
                />
              )
            }

          })}
        </div>



      </div>
    </div>
  )
}

export default Portfolio