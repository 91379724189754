/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import check from '../../images/portfolio/check-mark.png';
import flower from '../../images/portfolio/flower.png';
import { toast, ToastContainer } from 'react-toastify';

function Packages({ vendor, }) {
  const [packageData, setpackageData] = useState([])
  const [catee, setCat] = useState('All')
  const [appear, setappear] = useState(true)

  const [packages, setPackage] = useState(null);
  const [user, setUser] = useState({
    portfolio_id: 0, service_name: "", date: "", time: "", duration: "", category: "corporate", customer_id: null,
    package: "", price: 0, gst: 0, quantity: 1, total_price: 0, fullname: "", email: "", contact: "", status: "Pending", vendor_id: null
  })
  console.log('catee', catee);


  const options = [
    { value: 'All', label: 'All', },
    { value: 'photographypackage', label: 'Photography', color: '#000000', fontWeight: 'medium-bold', width: '50%' },
    { value: 'videopackage', label: 'Videography', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'streampackage', label: 'Live Stream', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'dronespackage', label: 'Aerial Drone', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'vrpackage', label: '360 Virtual Tour', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'copywritingpackage', label: 'Copywriting', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'weddingpackage', label: 'Wedding', color: '#000000', fontWeight: 'medium-bold', width: '50%' },
    { value: 'funeralpackage', label: 'Funeral', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'birthdaypackage', label: 'Birthday', color: '#000000', fontWeight: 'medium-bold' },
    { value: 'potraitpackage', label: 'Portrait', color: '#000000', fontWeight: 'medium-bold' },
  ];

  const handlePackage = (packages, price, gst, total, packagess) => {
    console.log('packagess', packagess)
    setPackage(packagess)
    setUser({ ...user, package: packages, price: price, gst: gst, total_price: Number(price) + Number(gst) });
  }
  useEffect(() => {


  }, [vendor])
  const colorStyles = {
    menuList: styles => ({
      ...styles,
      fontStyle: '$monstert',
      fontWeight: 'normal',
      color: 'black',
      background: 'white',
      opacity: '1',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsl(147, 50%, 47%, 0.5)'
        : isSelected
          ? 'hsl(147, 50%, 47%, 1)'
          : 'white'

    }),
    menu: (styles) => ({
      ...styles,
      border: "1px solid",
      width: "14vw",
      top: '2.5rem'

    }),
  }

  console.log('vendor', vendor);

  useEffect(() => {

  })

  function condition(e) {
    if (vendor && catee === 'All') {
      return (<h1 className="d-flex ">Please Select Services...!</h1>)

    }
    else if (vendor && vendor[catee]) {
      return (
        <div className='row no-gutters'>

          {(vendor[catee][0].descriptionA && vendor[catee][0].priceA) && Object.keys(vendor[catee][0]).length !== 0 && <div className='col-md-4'>
            <p className='package'>Package A</p>
            <p>{vendor[catee][0]?.descriptionA}</p>

            {vendor[catee][0].benefitAone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAone}</p>
              </div>
              : ""}

            {vendor[catee][0].benefitAtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAtwo}</p>
              </div>
              : ""}

            {vendor[catee][0].benefitAthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAthree}</p>
              </div>
              : ""}
            {/* {''} */}

            {vendor[catee][0].benefitAfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAfour}</p>
              </div>
              : ""}

            <p className='price font-weight-bold'>${vendor[catee][0]?.priceA}</p>


          </div>}

          {(vendor[catee][1].descriptionB && vendor[catee][1].priceB) && Object.keys(vendor[catee][1]).length !== 0 && <div className='col-md-4'>
            <p className='package'>Package B</p>
            <p>{vendor[catee][1]?.descriptionB}</p>

            {vendor[catee][1].benefitBone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBone}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBtwo}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBthree}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBfour}</p>
              </div>
              : ""}

            <p className='price font-weight-bold'>${vendor[catee][1]?.priceB}</p>

          </div>}

          {(vendor[catee][2].descriptionC && vendor[catee][2].priceC) && Object.keys(vendor[catee][2]).length !== 0 && <div className='col-md-4'>
            <p className='package'>Package C</p>
            <p>{vendor[catee][2]?.descriptionC}</p>

            {vendor[catee][2].benefitCone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCone}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCtwo}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCthree}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCfour}</p>
              </div>
              : ""}

            <p className='price font-weight-bold'>${vendor[catee][2]?.priceC}</p>
          </div>}

        </div>)
    }
    else {
      return <h1>No Package</h1>
    }
  }



  return (
    <div className='packages'>
      <ToastContainer />
      <img src={flower} alt="" className='flower' />
      <div className='container'>
        <div className='join'>
          <h2>Packages</h2>


          <div className='select-option'>
            <Select
              className='dropdowns'
              styles={colorStyles}
              options={options}
              onChange={(e) => {
                setCat(e.value)
                console.log(e)
              }
              }
              // value={catee}
              value={options.filter(({ value }) => value === catee)}

            />
          </div>
        </div>

        {condition()}





      </div>
    </div>
  )
}

export default Packages