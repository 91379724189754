import React, {useState, useEffect} from 'react'
import Hero from '../components/blog/Hero'
import Latest from '../components/blog/Latest'
import Older from '../components/blog/Older'
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import axios from 'axios';
import { withRouter } from 'react-router-dom'

function Blog() {
  const [blogsArray, setBlogLatest] = useState([]);
  const [oldBlogsArray, setBlogOld] = useState([]);

  const getBlogs = async () => {
    try {
      const blogs = await axios.get('https://api.trippincreatives.com/api/blogs?sort=id:desc&&populate=image');
      console.log("blogs",blogs);
      setBlogLatest(blogs.data.data)
    }
    catch(e) {
      console.log(e);
    }
  }
  const getOldBlogs = async () => {
    try {
      const blogs = await axios.get('https://api.trippincreatives.com/api/blogs?populate=image');
      setBlogOld(blogs.data.data)
    }
    catch(e) {
      console.log("error occured in blog page",e);
    }
  }

  useEffect(()=>{
    getBlogs()
    getOldBlogs()
  },[])

// console.log(blogsArray)
  return (
    <div className='blog row no-gutters'>
      <Navbar />
      <Hero blog={blogsArray.length > 0 ? blogsArray[0]: null}  />
      <Latest blogsArray={blogsArray} />
      <Older oldBlogsArray={oldBlogsArray}/>
      <Footer />
    </div>
  )
}

export default withRouter(Blog)
