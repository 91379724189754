import React,{ useState,useEffect} from 'react'
import Hero from '../components/about/Hero'
import MyWorks from '../components/about/MyWorks'
import Footer from '../components/footer'
import Photography from '../components/landing/Photography'
import Navbar from '../components/topNav'
import axios from 'axios';

function AboutUs() {
const [userData, setUserData] = useState([]) 
const [sectionleft, setSectionLeft] = useState([])
const [sectionright, setSectionRight] = useState([]) 
const [image, setImage] = useState([])
const [img, setImg] = useState([])
const [pic, setPic] = useState([])

//Gallery
const [gallerData, setgallerData] = useState([])

async function getData() {

  try {
    const res = await axios.get(
      "https://api.trippincreatives.com/api/about-uspage?populate=%2A")
    console.log("res", res.data);
    setUserData(res.data.data.attributes);
    setgallerData(res.data.data.attributes.Gallery.data)
    setSectionLeft(res.data.data.attributes.SecondSectionLeft.data.attributes)
    setSectionRight(res.data.data.attributes.SecondSectionRight.data.attributes)
    setImage(res.data.data.attributes.Number01Image.data.attributes)
    setImg(res.data.data.attributes.Number02Image.data.attributes)
    setPic(res.data.data.attributes.Number03Image.data.attributes)



  } catch (e) {
    console.log("some error occured while listing")
  }

}
useEffect(()=>{getData()},[]);
// console.log("AboutuserData", userData);

  return (
    <div className='about row no-gutters'>
      <Navbar />
      <Hero userdata={userData}/>
      <MyWorks userdata={userData} 
              sectionleft={sectionleft}
              sectionright={sectionright}  
              image ={image}
              img={img}
              pic={pic}
              />
      <Photography carouselpic={gallerData}/>
      <Footer />
    </div>
  )
}

export default AboutUs


