import axios from 'axios';
import React, { useState, useEffect } from 'react'
// import back from '../../images/appointment/back.png';
import back from '../../src/images/appointment/back.png';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import eye from '../images/login/eye.png';
import eyeSlash from '../images/login/eye-slash.png';

function ChangePassword({ history }) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPd, setIsRevealPd] = useState(false);
  const [isRevealPwds, setIsRevealPwds] = useState(false);

  const [password, setpassword] = useState({ old: "", new: "", confirm: "" })
  const [user, setuser] = useState({})
  useEffect(() => {
    setuser(JSON.parse(localStorage.getItem("user")))

  }, [])
  function logout() {
    localStorage.clear();
    window.location.href = '/';
  }
  const handleChange = e => {
    setpassword({ ...password, [e.target.name]: e.target.value })
  }

  const fetchData = async (e) => {
    e.preventDefault()
    if (password.new === '' || password.old === '' || password.confirm === '') {
      return toast.error("All Fields Required")
    }
    if (password.new === password.old || password.confirm === password.old) {
      return toast.error("Please Enter New Password")

    }

    if (password.new !== password.confirm) {
      return toast.error("Confirm Password is not equal to New Password")
    }


    var data = JSON.stringify({
      "identifier": user.email,
      "password": password.old
    });

    var config = {
      method: 'post',
      url: 'https://api.trippincreatives.com/api/auth/local/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.jwt) {
          var axios = require('axios');
          var data = JSON.stringify({
            "identifier": user.email,
            "password": password.new
          });

          var config = {
            method: 'put',
            url: 'https://api.trippincreatives.com/api/users/' + user.id,
            headers: {
              'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614',
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              toast.success('Your Password is changed');
              logout();
              if (history.location.pathname.includes('vendor')) {
                history.push('/vendor')
              }
              else {
                history.push('/profile')
              }
            })
            .catch(function (error) {
              console.log(error);
              toast.error("Password Incorrect");
            });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Password Incorrect");
      });


    //   try{
    //   const response = await axios.post(`https://api.trippincreatives.com/api/auth/local/`,{
    //     "identifier":user.email,
    //     "password":password.old
    //   },config)
    //   if(response.data.jwt){
    //     try{
    //     const res = await axios.put(`https://api.trippincreatives.com/api/users/${user.id}`,{
    //       "identifier":user.email,
    //       "password":password.new
    //     },config)
    //     // console.log(res)
    //     toast.success('Your Password is changed');
    //     if (history.location.pathname.includes( 'vendor') ) {
    //       history.push('/vendor')
    //     }
    //     else {
    //       history.push('/')
    //     }
    //   }catch(error){
    //     toast.error("Password Incorrect");
    //   }
    //   }
    // }catch(error){
    //     toast.error("Password Incorrect");
    //   }
  }
  console.log(history)
  return (
    <div className='profile row no-gutters mt-5'>
      <ToastContainer />

      <div className='container'>
        <Link to='/'><img src={back} alt="" className='back' /></Link>

        <div className='row no-gutters'>
          <div className='col'>
            <div className='form-submission'>
              <p className='my-profile'>Change Password</p>
              <hr />
              <form>
                <div className="form-group position-relative">
                  <label>Old Password</label>
                  <input type={isRevealPwd ? "text" : "password"} name="old" onChange={handleChange} className='form-control password-field' />
                  <img
                    style={{ width: '3%', height: '45%', bottom: '0.2rem', right: '1%' }}
                    className='eye_ic'
                    src={!isRevealPwd ? eyeSlash : eye}
                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                  />
                </div>
                <div className='d-flex'>
                  <div className='form-group position-relative w-100 mr-4'>
                    <label>New Password</label>
                    <input type={isRevealPd ? "text" : "password"} name="new" onChange={handleChange} className='form-control password-field' />
                    <img
                      style={{ width: '6%', height: '33%', bottom: '30%', right: '1%' }}
                      className='eye_ic1'
                      src={!isRevealPd ? eyeSlash : eye}
                      onClick={() => setIsRevealPd(prevState => !prevState)}
                    />
                  </div>
                  <div className='w-100 form-group position-relative'>
                    <label>Confirm Password</label>
                    <input type={isRevealPwds ? "text" : "password"} name="confirm" onChange={handleChange} required className='form-control password-field' autoComplete="off" />
                    <img
                      style={{ width: '6%', height: '33%', bottom: '30%', right: '2%' }}
                      className='eye_ic1'
                      src={!isRevealPwds ? eyeSlash : eye}
                      onClick={() => setIsRevealPwds(prevState => !prevState)}
                    />
                  </div>
                </div>
                <div className='bottom'>
                  <div className='buttons-div'>
                    <button className='btn btn-success' onClick={fetchData}>Save</button>
                    <button className='btn btn-cancel' onClick={() => history.push('/profile')}>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword



