import React from 'react'
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import articleOne from '../../images/blog/article-one.png';
import articleTwo from '../../images/blog/article-two.png';
import articleThree from '../../images/blog/article-three.png';
import articleFour from '../../images/blog/article-four.png';
import arrowRight from '../../images/blog/arrow-right.png';

// const project = [
//   {img: articleOne},
//   {img: articleTwo },
//   {img: articleThree},
//   {img: articleFour},
// ]

function Older({history,oldBlogsArray}) {
  const url = "https://api.trippincreatives.com";
  return (
    <div className='older'>
      <div className='container'>
        <h2>Older Articles</h2>
      <div className='row no-gutters'>
      {oldBlogsArray.map((c,i)=>{
        return(
        <div className='col-md-6 mb-5 d-flex'>
           <img src={`${url}${c.attributes.image.data.attributes.url}`} alt="" className='old'/>
          <div>
          <p> Posted on {moment(c.attributes.publishedAt).format('Do MMM YY')}</p>
          <h6>{c.attributes.title.slice(0, 120)}...</h6>
          <div style={{cursor:'pointer'}} onClick={()=> history.push(`/blog/${c.id}`)} className='d-flex align-items-center'><p  className='mr-3 mb-0 readTxt'>Read More</p><img src={arrowRight} alt="" /></div>
          </div>
          </div>
        )
        })}
      </div>  
      </div>
    </div>
  )
}

export default withRouter(Older)