import React,{useEffect, useState} from 'react'
import Hero from '../components/details/Hero'
import Packages from '../components/details/Packages'
import Portfolio from '../components/details/Portfolio'
import Reviews from '../components/details/Reviews'
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import { useParams } from 'react-router-dom'
import axios from 'axios';

function PortfolioDetails({ location }) {
    console.log('location', location);
  const [detail,setDetail]=useState(null)
  const {id}=useParams();

  const getVendor = async () => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users?populate=%2A');
      for(let i=0;i<response.data.length;i++)
      {
        if(response.data[i].id==id)
        {
          setDetail(response.data[i]);
          break;
        }
      }
      //console.log(response.data)
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(()=>{
    if(!location.state)
    getVendor()
    else
    console.log("Details already got");
    
  },[])

  useEffect(()=>{
   console.log(detail)
  },[detail])

  if(location.state)
  {
    return (
      <div className='details row no-gutters'>
        <Navbar />
        <Hero obj={location.state.ar} />
        <Packages vendor={location.state.ar} />
        <Portfolio vendor={location.state.ar} />
        <Reviews reviews={location.state.review} details={location.state.ar}/>
        <Footer />
      </div>
    )

  }
  else if(detail){
    return (
      <div className='details row no-gutters'>
        <Navbar />
        <Hero obj={detail} />
        <Packages vendor={detail} />
        <Portfolio vendor={detail} />
        <Reviews reviews={location.state.review} details={location.state.ar}/>
        <Footer />
      </div>
    )
  }
  else{
    return(
      <div className='details row no-gutters'>
      <Navbar />
      <Footer />
    </div>
    )
  }
}

export default PortfolioDetails