/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import glasses from '../../images/appointment/sunglass.png';
import pineapple from '../../images/appointment/pineapple.png';
import logo from '../../images/logo.png';
import next from '../../images/appointment/next.png';
import back from '../../images/appointment/back.png';
import userEvent from '@testing-library/user-event';
import { Link } from 'react-router-dom';

function StepOne({ changeStatus, handleChange, user, completed }) {
  // console.table('user',user);

  const [showPersonal, setShowPersonal] = useState(false);

  return (
    <div className='step-one' >
      <div className='completed' style={{ borderTop: '8px solid #18BB78', width: `${completed}%`, marginTop: 0 }}> </div>
      <img src={glasses} alt="" className='glass' />
      <img src={pineapple} alt="" className='pineapple' />
      <div className='container'>
        <Link to='/'><img src={back} alt="" className='back' /></Link>
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="" />
        </Link>
        <h2>Select the type of services</h2>
        <div style={{ textAlign: "left", fontWeight: "bold" }}>Please Select Type</div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div className="radio" style={{ display: "flex", flexDirection: "row", position: "relative" }}>
            <input
              name='category'
              type="radio"
              value="corporate"
              checked={user.category === "corporate"}
              onChange={handleChange}
            />
            <label style={{ position: "absolute", top: 10, left: 20, }}>
              Corporate
            </label>
          </div>
          <div className="radio" style={{ display: "flex", flexDirection: "row", marginLeft: 100, position: "relative" }}>
            <input
              name='category'
              type="radio"
              value="personal"
              checked={user.category === "personal"}
              onChange={handleChange}
            />
            <label style={{ position: "absolute", top: 10, left: 20 }}>
              Personal
            </label>
          </div>
        </div>

        {user.category === "corporate" && <select id="cars" name="service_name" onChange={handleChange} value={user.service_name}>
          <option value="">Please select service</option>
          <option value="photographypackage" >Photography</option>
          <option value="videopackage" >Videography</option>
          <option value="streampackage" >Live Stream</option>
          <option value="dronespackage" >Aerial Drone</option>
          <option value="vrpackage" >360 Virtual Tour</option>
          <option value="copywritingpackage" >Copywriting</option>
        </select>}
        {user.category === "personal" && <select id="cars" name="service_name" onChange={handleChange} value={user.service_name}>
          <option value="">Please select service</option>
          <option value="weddingpackage" >Wedding</option>
          <option value="funeralpackage" >Funeral</option>
          <option value="birthdaypackage" >Birthday</option>
          <option value="potraitpackage" >Potrait</option>
        </select>}
        <div className='d-flex'>
          <input type="date" placeholder="Pick a date" name="date" onChange={handleChange} value={user.date} />
          <input type="time" name="time" onChange={handleChange} value={user.time} />
          <select id="cars" name="duration" onChange={handleChange} value={user.duration}>
            <option value="">Select duration</option>
            <option value="1 Hour">1 Hour</option>
            <option value="2 Hour">2 Hour</option>
            <option value="3 Hour">3 Hour</option>
            <option value="4 Hour">4 Hour</option>
          </select>
        </div>
        <button onClick={() => changeStatus(3)} className='btn'>Next</button>
      </div>
    </div>
  )
}

export default StepOne