import React, { useState,useEffect } from 'react'
import {ShareSocial} from 'react-share-social' 
import { withRouter } from 'react-router-dom';
import appointement from '../../images/appointment.png';

import share from '../../images/portfolio/share.png';
import loginImg from '../../images/login/login-img.png';
import { Modal } from 'react-responsive-modal';

function Hero({obj, location, history}) {
  const [open, setOpen] = useState(false)
  const [user, setuser] = useState(false)
  
  const authinticate = () => {
 
    if(user){
      console.log('auth',user);
     history.push("/appointment");
    }
   else{
     history.push("/login");
    //  toast.error("Please Login First...!")
    }
  }
  useEffect(() => {
    setuser(localStorage.getItem("isAuthenticated"))
  }, [])

  const style = {
    background: 'linear-gradient(45deg, #80FF72 30%, #7EE8FA 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  };
  console.log(obj)
  return (
    <div className='hero'>
      
      {obj &&<div className='container'>
      <Modal open={open}  onClose={()=> setOpen(false)} center>
        <h5>Share with others</h5>
        <ShareSocial 
        onSocialButtonClicked={ data=> console.log(data)}  
     style={style}
    
    url ={`https://trippincreative.com/details/${obj.id}`}

     socialTypes={['facebook','twitter','linkedin']}
   />
      </Modal>

      <div className='row no-gutters'>
        <div className='col-md-6'>
          <h1>{obj.fullname}</h1>
          <p className="bold">About Me</p>
          <p>{obj.aboutme||"No About Me Available"}</p>
          <p className="bold">Services</p>
          <p>{obj && obj.services && obj.services.map(ser=> (<p>{ser}</p>))}</p>
          <div className='d-flex align-items-center mt-5'>
          <img 
            className ='button'
            // onClick={authinticate}
            onClick ={ () => authinticate()}
             src={appointement} alt="" />

          <p style={{cursor:'pointer'}} onClick={()=> setOpen(true)} className='green'>Share Profile <img src={ share } alt=""/></p>
          
          </div>
         </div>
         <div className='col-md-6 play'>
          {obj.image&&<img src={`https://api.trippincreatives.com${obj.image.url}`} alt=""/>}
         </div>
      </div>  
      </div>}
    </div>
  )
}

export default withRouter(Hero)