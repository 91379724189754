/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import plus from '../../images/vendor/plus.png';
import { Toast } from 'bootstrap';

function Packages({ history, changeStatus }) {

  const packageJSON = {
    '360 Virtual Tours': "vrpackage",
    'Videography': "videopackage",
    'Photography': "photographypackage",
    'Copywriting': "copywritingpackage",
    'Aerial Drones': "dronespackage",
    'Live Stream': "streampackage",
    'Funeral': "funeralpackage",
    'Wedding': "weddingpackage",
    'Birthday': "birthdaypackage",
    'Portrait': "potraitpackage"
  }


  const [packageAStat, setpackageAStat] = useState(true)
  const [packageBStat, setpackageBStat] = useState(true)
  const [packageCStat, setpackageCStat] = useState(true)
  const [packageAStatus, setpackageAStatus] = useState(false)
  const [packageBStatus, setpackageBStatus] = useState(false)
  const [packageCStatus, setpackageCStatus] = useState(false)
  const [services, setservices] = useState([]);
  const [singleService, setsingleService] = useState("");
  const [combine, setcombine] = useState([]);
  const [packageDetails, setpackageDetails] = useState([]);

  //mychange
  const [da, setDa] = useState("")
  const [ba1, setBa1] = useState("")
  const [ba2, setBa2] = useState("")
  const [ba3, setBa3] = useState("")
  const [ba4, setBa4] = useState("")
  const [pa, setPa] = useState("")

  const [db, setDb] = useState("")
  const [bb1, setBb1] = useState("")
  const [bb2, setBb2] = useState("")
  const [bb3, setBb3] = useState("")
  const [bb4, setBb4] = useState("")
  const [pb, setPb] = useState("")

  const [dc, setDc] = useState("")
  const [bc1, setBc1] = useState("")
  const [bc2, setBc2] = useState("")
  const [bc3, setBc3] = useState("")
  const [bc4, setBc4] = useState("")
  const [pc, setPc] = useState("")
  //mychange


  useEffect(() => {


    const fetchData = async () => {
      // Getting profile picture
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config)
        setpackageDetails(response.data)
        setservices(response.data.services);
        if (singleService === "") {
          setsingleService(response.data.services[0])
        }

        console.log("Response", response.data)
        // response.data.packagea !== null ? setpackageAStatus(true) : setpackageAStatus(false)
        // response.data.packageb !== null ? setpackageBStatus(true) : setpackageBStatus(false)
        // response.data.packagec !== null ? setpackageCStatus(true) : setpackageCStatus(false)
        // setpackageA(response.data.packagea)
        // setpackageAStat(response.data.packagea)
        // setpackageBStat(response.data.packageb)
        // setpackageCStat(response.data.packagec)
        // setpackageB(response.data.packageb)
        // setpackageC(response.data.packagec)
        var p;
        if (singleService === "") {
          p = response["data"][packageJSON[response.data.services[0]]]
          console.log("PPPP", p, packageJSON[response.data.services[0]])
        }
        else {
          p = response["data"][packageJSON[singleService]]
          console.log("PPPP", p, packageJSON[singleService])
        }


        if (p != null) {
          if (p.length >= 1) {
            setpackageAStatus(true)
            setDa(p[0].descriptionA)
            setBa1(p[0]["benefitAone"])
            setBa2(p[0]["benefitAtwo"])
            setBa3(p[0]["benefitAthree"])
            setBa4(p[0]["benefitAfour"])
            setPa(p[0]["priceA"])
          }
          if (p.length >= 2) {
            setpackageBStatus(true)
            setDb(p[1]["descriptionB"])
            setBb1(p[1]["benefitBone"])
            setBb2(p[1]["benefitBtwo"])
            setBb3(p[1]["benefitBthree"])
            setBb4(p[1]["benefitBfour"])
            setPb(p[1]["priceB"])
          }
          if (p.length >= 3) {
            setpackageCStatus(true)
            setDc(p[2]["descriptionC"])
            setBc1(p[2]["benefitCone"])
            setBc2(p[2]["benefitCtwo"])
            setBc3(p[2]["benefitCthree"])
            setBc4(p[2]["benefitCfour"])
            setPc(p[2]["priceC"])
          }

          toast.success("Package Loaded", {
            position: "top-right",
            autoClose: 2000,
          })

        }
        else {
          setpackageAStatus(false)
          setDa("")
          setBa1("")
          setBa2("")
          setBa3("")
          setBa4("")
          setPa("")

          setpackageBStatus(false)
          setDb("")
          setBb1("")
          setBb2("")
          setBb3("")
          setBb4("")
          setPb("")

          setpackageCStatus(false)
          setDc("")
          setBc1("")
          setBc2("")
          setBc3("")
          setBc4("")
          setPc("")
          toast.success("Package Loaded", {
            position: "top-right",
            autoClose: 2000,
          })

        }


      } catch (error) {
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
    fetchData()

  }, [singleService])

  const updatePackage = async () => {

    if (!packageDetails.phone || packageDetails.phone === "") {
      return toast.error("Add Contact Details in Profile to add Packages", {
        position: "top-right",
        autoClose: 3000,
      })
    }


    if (((ba1.length > 0 || ba2.length > 0 || ba3.length > 0 || ba4.length > 0) && !(da.length > 0 && pa.length > 0)) || (!(ba1.length > 0 || ba2.length > 0 || ba3.length > 0 || ba4.length > 0) && (da.length > 0 && pa.length > 0))) {
      console.log("Error from A")
      return toast.error("Add Complete Details for Package A", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    if (((bb1.length > 0 || bb2.length > 0 || bb3.length > 0 || bb4.length > 0) && !(db.length > 0 || pb.length > 0)) || (!(bb1.length > 0 || bb2.length > 0 || bb3.length > 0 || bb4.length > 0) && (db.length > 0 || pb.length > 0))) {
      console.log("Error from B")
      return toast.error("Add Complete Details for Package B", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    if (((bc1.length > 0 || bc2.length > 0 || bc3.length > 0 || bc4.length > 0) && !(dc.length > 0 || pc.length > 0)) || (!(bc1.length > 0 || bc2.length > 0 || bc3.length > 0 || bc4.length > 0) && (dc.length > 0 || pc.length > 0))) {
      console.log("Error from C")
      return toast.error("Add Benefits or Price for Package C", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    if (Number.isInteger(Number(pa))) {
      if (Number(pa) < 0) {
        return toast.error("Please Enter a Positive Value for price of Package A", {
          position: "top-right",
          autoClose: 3000,
        })
      }
    }
    else {
      return toast.error("Please Enter a Valid Price for Package A", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    if (Number.isInteger(Number(pb))) {
      if (Number(pb) < 0) {
        return toast.error("Please a Positive Value for price of Package B", {
          position: "top-right",
          autoClose: 3000,
        })
      }
    }
    else {
      return toast.error("Please Enter a Valid Price for Package B", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    if (Number.isInteger(Number(pc))) {
      if (Number(pc) < 0) {
        return toast.error("Please a Positive Value for price of Package C", {
          position: "top-right",
          autoClose: 3000,
        })
      }
    }
    else {
      return toast.error("Please Enter a Valid Price for Package C", {
        position: "top-right",
        autoClose: 3000,
      })
    }

    const user = JSON.parse(localStorage.getItem("user"))
    var axios = require('axios');
    let d = {}
    d[packageJSON[singleService]] = [
      {
        "descriptionA": da,
        "benefitAone": ba1,
        "benefitAtwo": ba2,
        "benefitAthree": ba3,
        "benefitAfour": ba4,
        "priceA": pa
      },
      {
        "descriptionB": db,
        "benefitBone": bb1,
        "benefitBtwo": bb2,
        "benefitBthree": bb3,
        "benefitBfour": bb4,
        "priceB": pb
      },
      {
        "descriptionC": dc,
        "benefitCone": bc1,
        "benefitCtwo": bc2,
        "benefitCthree": bc3,
        "benefitCfour": bc4,
        "priceC": pc
      },
      { 'package': { singleService } }
    ]
    var data = JSON.stringify(d);

    var config = {
      method: 'put',
      url: 'https://api.trippincreatives.com/api/users/' + user.id,
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success("Package updated", {
          position: "top-right",
          autoClose: 2000,
        })
      })
      .catch(function (error) {
        console.log(error);
      });



  }



  let corporate = ["Videography", "360 Virtual Tours", "Photography", "Copywriting", "Aerial Drones", "Live Stream"]

  return (
    <>
      <div className='packages'>
        <div className='container'>
          <div className='row no-gutters'>
            <div className='col-md-4'>
              {(services && services.filter(a => corporate.includes(a)).length > 0) && <p>Corporate</p>}
              <ul style={{ cursor: 'pointer' }}>
                {services && services.filter(a => corporate.includes(a)).map((s, i) => (
                  <li className={s === singleService ? "active" : ""} onClick={() => { setsingleService(s) }}>{s}</li>
                ))}
              </ul>
              {(services && services.filter(a => !corporate.includes(a)).length > 0) && <p>Personal</p>}
              <ul style={{ cursor: 'pointer' }}>
                {services && services.filter(a => !corporate.includes(a)).map((s, i) => (
                  <li className={s === singleService ? "active" : ""} onClick={() => setsingleService(s)}>{s}</li>
                ))}
              </ul>
            </div>
            <div className='col info'>
              <p>Package Info</p>
              <hr />
              <div className='row no-gutters'>
                <div className='col-md-4 mobile-margin'>
                  {
                    packageAStatus ?
                      <>
                        <div className='packageA'>
                          <p>Package A</p>
                          <label>Description</label>
                          <textarea type="text" name="descriptionA" onChange={(e) => { setDa(e.target.value) }} value={da} className='form-control' rows={5} />
                          <label className='mt-4'>Benefit 1</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBa1(e.target.value) }} value={ba1} name="benefitAone" className='form-control' />
                          <label className='mt-4'>Benefit 2</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBa2(e.target.value) }} value={ba2} name="benefitAtwo" className='form-control' />
                          <label className='mt-4'>Benefit 3</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBa3(e.target.value) }} value={ba3} name="benefitAthree" className='form-control' />
                          <label className='mt-4'>Benefit 4</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBa4(e.target.value) }} value={ba4} name="benefitAfour" className='form-control' />
                          <label>Price per hour</label>
                          <input onWheel={(e) => e.target.blur()} type="number" name="priceA" onChange={(e) => { setPa(e.target.value) }} value={pa} className='form-control' />
                        </div>
                        <div className='btn-div'>
                          <button onClick={updatePackage} className='btn btn-success mr-3' >{packageAStat === null ? 'Save' : 'Update'}</button>
                          <button className='btn btn-cancel' onClick={() => (setpackageAStatus(false))}>Cancel</button>
                        </div>
                      </> :
                      <div className='uploadBox' onClick={() => setpackageAStatus(true)}>
                        <img src={plus} alt="" />
                      </div>
                  }
                </div>
                <div className='col-md-4 mobile-margin'>
                  {
                    packageBStatus ?
                      <>
                        <div className='packageB'>
                          <p>Package B</p>
                          <label>Description</label>
                          <textarea type="text" className='form-control' name="descriptionB" onChange={(e) => { setDb(e.target.value) }} value={db} rows={5} />
                          <label className='mt-4'>Benefit 1</label>
                          <input type="text" placeholder="Type here .." name="benefitBone" onChange={(e) => { setBb1(e.target.value) }} value={bb1} className='form-control' />
                          <label className='mt-4'>Benefit 2</label>
                          <input type="text" placeholder="Type here .." name="benefitBtwo" onChange={(e) => { setBb2(e.target.value) }} value={bb2} className='form-control' />
                          <label className='mt-4'>Benefit 3</label>
                          <input type="text" placeholder="Type here .." name="benefitBthree" onChange={(e) => { setBb3(e.target.value) }} value={bb3} className='form-control' />
                          <label className='mt-4'>Benefit 4</label>
                          <input type="text" placeholder="Type here .." name="benefitBfour" onChange={(e) => { setBb4(e.target.value) }} value={bb4} className='form-control' />
                          <label>Price per hour</label>
                          <input onWheel={(e) => e.target.blur()} type="number" name="priceB" onChange={(e) => { setPb(e.target.value) }} value={pb} className='form-control' />
                        </div>
                        <div className='btn-div'>
                          <><button onClick={updatePackage} className='btn btn-success mr-3' >{!packageBStat ? 'Save' : 'Update'}</button>
                            <button className='btn btn-cancel' onClick={() => (setpackageBStatus(false))}>Cancel</button></>
                        </div>
                      </> :
                      <div className='uploadBox' onClick={() => setpackageBStatus(true)}>
                        <img src={plus} alt="" />
                      </div>
                  }
                </div>
                <div className='col-md-4 mobile-margin'>
                  {
                    packageCStatus ?
                      <>
                        <div className='packageC'>
                          <p>Package C</p>
                          <label>Description</label>
                          <textarea type="text" name="descriptionC" onChange={(e) => { setDc(e.target.value) }} value={dc} className='form-control' rows={5} />
                          <label className='mt-4'>Benefit 1</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBc1(e.target.value) }} value={bc1} name="benefitCone" className='form-control' />
                          <label className='mt-4'>Benefit 2</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBc2(e.target.value) }} value={bc2} name="benefitCtwo" className='form-control' />
                          <label className='mt-4'>Benefit 3</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBc3(e.target.value) }} value={bc3} name="benefitCthree" className='form-control' />
                          <label className='mt-4'>Benefit 4</label>
                          <input type="text" placeholder="Type here .." onChange={(e) => { setBc4(e.target.value) }} value={bc4} name="benefitCfour" className='form-control' />
                          <label>Price per hour</label>
                          <input onWheel={(e) => e.target.blur()} type="number" name="priceC" onChange={(e) => { setPc(e.target.value) }} value={pc} className='form-control' />
                        </div>
                        <div className='btn-div'>
                          {<><button onClick={updatePackage} className='btn btn-success mr-3' >{!packageCStat ? 'Save' : 'Update'}</button>
                            <button className='btn btn-cancel' onClick={() => (setpackageCStatus(false))}>Cancel</button></>}
                        </div>
                      </> :
                      <div className='uploadBox' onClick={() => setpackageCStatus(true)}>
                        <img src={plus} alt="" />
                      </div>
                  }
                </div>
                {/* <button onClick={combinePackages}>Click</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Packages