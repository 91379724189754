import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import Community from '../components/landing/Community';
import Hero from '../components/landing/Hero';
import Photography from '../components/landing/Photography';
import ReadMore from '../components/landing/ReadMore';
import Services from '../components/landing/Services';
import Testimonials from '../components/landing/Testimonials';
import Works from '../components/landing/Works';
import Navbar from '../components/topNav';
import axios from 'axios';
import { toast } from 'react-toastify';


const Home = ({ history }) => {
  // console.log(history);

   const [num, setNum] = useState(0);
  const [userData, setUserData] = useState([]);
  const [aerialserviceData, setaerialServiceData] = useState([]);
  const [photoserviceurl, setphotoServiceurl] = useState([]);
  const [videoserviceurl, setvideoserviceurl] = useState([]);
  const [livestream, setlivestream] = useState([]);
  const [virtualserviceurl, setvirtualserviceurl] = useState([]);
  const [copywritingserviceurl, setcopywritingserviceurl] = useState([]);

  //Testimonials
  const [testimonial, setTestimonial] = useState([]);

  //Carousel
  const [carouselpic, setcarouselpic] = useState([]);
 

  // const [num, setNum] = useState(0)
  // const [picture, setpicture] = useState("")
  // const profilepic = async () => {
  //   // Getting profile picture

  //   try {
  //     const config = {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  //     };
  //     const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config)
  //     setpicture(response.data.image.formats.thumbnail.url)
   
  //     console.log('pic',response.data.image.formats.thumbnail.url)
  //   } catch (error) {
  //     toast.error(error.response.data.error.message);
  //   }
  // }
  useEffect(() => { 
   // fetchData()
    getData()
    
  }, [])

   
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get(`/auth/facebook/callback${history.location.search}`)
    //     localStorage.setItem('token', response.data.jwt);
    //     localStorage.setItem('isAuthenticated', true);
    //     localStorage.setItem('user', JSON.stringify(response.data.user));
    //     toast.success("User Logged In")
    //     console.log(response.data.user);
    //     response.data.user.fullname === '' || response.data.user.phone === '0' ? history.push('/profile') : history.push('/')
    //   } catch (error) {
    //     toast.error(error.response.data.error.message);
    //     // console.log(e)
    //   }
    // }
    // if (history.location.search) {
    //   fetchData()
    // }


  async function getData() {

    try {
      const res = await axios.get(
        "https://api.trippincreatives.com/api/home?populate=deep,3")
      console.log("res1", res);
      setUserData(res.data.data.attributes);
      setaerialServiceData(res.data.data.attributes.AerialDronesService.data.attributes);
      setphotoServiceurl(res.data.data.attributes.PhotographyService.data.attributes);
      setvideoserviceurl(res.data.data.attributes.VideoGraphyService.data.attributes);
      setlivestream(res.data.data.attributes.LivestreamService.data.attributes);
      setvirtualserviceurl(res.data.data.attributes.virtualtourService.data.attributes);
      setcopywritingserviceurl(res.data.data.attributes.CopywritingService.data.attributes);
      setTestimonial(res.data.data.attributes.Testimonial);
     
      setcarouselpic(res.data.data.attributes.LastCarousel.data);

    } catch (e) {
      console.log("some error occured while listing")
    }

  }

  console.log("testimonial", testimonial);

  return (
    <div className='home row no-gutters'>
      <Navbar />
      <Hero userdata={userData} />
      <div className='bg'>
           <Services aerialservicedata={aerialserviceData} 
                     photoserviceurl={photoserviceurl}
                     videoserviceurl={videoserviceurl} 
                     livestream={livestream}
                     virtualserviceurl={virtualserviceurl} 
                     copywritingserviceurl={copywritingserviceurl}
                    
                  
            />

            <Works userdata={userData} />
            <Testimonials testimonial={testimonial} />
      </div>
            <Community />
            <ReadMore userdata={userData}/>
            <Photography carouselpic={carouselpic}/>
            <Footer />
    </div>
  )
}
export default Home;