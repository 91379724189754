import React from 'react'
import { Link } from 'react-router-dom';

import thanku from '../../images/appointment/thanku.png';
import main from '../../images/appointment/main.png';
import logo from '../../images/logo.png';
import {ToastContainer,toast} from "react-toastify";

function ThanksYou({order}) {
  React.useEffect(()=>{
    toast.success("Your Order Has Been Placed")
  },[])

  return (
 <>
     <div className='appointment  row no-gutters'>
     <img src={logo} alt="" className='img-logo' />
     <div className='thank-you'>
    
         <img style={{marginLeft:'6rem'}} src={thanku} alt="" />
         <h2 style={{marginLeft:'4rem'}}>Thank You!</h2>
         <p className='ml-5'>We will contact you shortly.</p>
         <Link to="/home"><img src={main} alt="" className='mt-4 ml-5' /></Link>
    
     </div>
   </div>
   <ToastContainer/>
   </>
  )
}

export default ThanksYou