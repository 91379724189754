/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import star from '../images/portfolio/star.png';
import reviewOne from '../images/portfolio/review-one.png';
import {toast} from "react-toastify";
import sendButton from "../images/sendbtn.png";
//import vendorAdminScss from "../css/pages/vendorAdmin.scss";
import { AiFillDelete,AiFillEdit } from "react-icons/ai";
function VendorAdmin({ history }) {
  
  const [vendorToEdit, setVendorToEdit] = useState(null);
  const [reviewData, setReviewData] = useState([]);
  const [reviewName, setReviewName] = useState("");
const [reviewText, setReviewText] = useState("");
const [reviewRating, setReviewRating] = useState(0);

const [reviewEdit, setReviewEdit] = useState(false);
const [reviewEditId, setReviewEditId] = useState(0);

// console.log(JSON.parse(vendor_id))
  const getAllReviews = async () => {
    var vendor_id =localStorage.getItem('user')
    vendor_id = JSON.parse(vendor_id)

    var config = {
      method: 'get',
      url: `https://api.trippincreatives.com/api/vendor-reviews?populate=%2A&filters[user][id][$eq]=${vendor_id.id}`,
      headers: { }
    };
    
    await axios(config)
    .then(function (response) {
      console.log("Reviews",response.data);

      setReviewData(response.data.data)

    })
    .catch(function (error) {
      console.log(error);
    });

  }    
  console.log('reviewData',reviewData);

  const getAverageRatingAndNumberOfReviews = (userID,r)=>{
    var count=0
    var sum=0
    var review=[]
    for(var i=0;i<r.length;i++){
      if(r[i].attributes.user.data.id==userID){
        count++;
        sum+=r[i].attributes.Rating
        review.push(r[i])
      }
    }
    return {count:count,avg: sum==0||count==0?0:Math.round(sum/count),review:review}
  }

  async function postReview() {
    var data = JSON.stringify({
        "data": {
          "Name": reviewName,
          "Rating": reviewRating,
          "Description": reviewText,
          "user": vendorToEdit.id,
        }
      });
      
      var config = {
        method: 'post',
        url: 'https://api.trippincreatives.com/api/vendor-reviews',
        headers: { 
          'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614', 
          'Content-Type': 'application/json'
        },
        data : data
      };

      if(reviewName==""||reviewText==""||reviewRating==0){
        toast.error("Please fill all the fields")
      }
      else{
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setReviewName("")
        setReviewText("")
        setReviewRating(0)
        toast.success("Review Added Successfully")
        getAllReviews()
      })
      .catch(function (error) {
        console.log(error);
        setReviewName("")
        setReviewText("")
        setReviewRating(0)
        toast.error("Error Occured")
        getAllReviews()
      });
    }
      
  }

  const deleteReview = async (id) => {
    var config = {
      method: 'delete',
      url: 'https://api.trippincreatives.com/api/vendor-reviews/'+id,
      headers: { 
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };
    
    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      toast.success("Review Deleted Successfully")
      getAllReviews()
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Error Occured")
      getAllReviews()
    });
  }    

  const editReview = async () => {
var data = JSON.stringify({
  "data": {
    "Name": reviewName,
    "Rating": reviewRating,
    "Description": reviewText,
    "user": vendorToEdit.id,
  }
});

var config = {
  method: 'put',
  url: 'https://api.trippincreatives.com/api/vendor-reviews/'+reviewEditId,
  headers: { 
    'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614', 
    'Content-Type': 'application/json'
  },
  data : data
};

await axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  setReviewEdit(false)
  setReviewEditId(0)
  setReviewName("")
  setReviewText("")
  setReviewRating(0)
  toast.success("Review Edited Successfully")
  getAllReviews()

})
.catch(function (error) {
  console.log(error);
  toast.error("Error Occured")
  getAllReviews()
  setReviewEdit(false)
  setReviewEditId(0)
  setReviewName("")
  setReviewText("")
  setReviewRating(0)
});


  }

  useEffect(() => {

    let a = JSON.parse(localStorage.getItem("user"));
    console.log("This is the user data from reviews",a)
    setVendorToEdit(a)
    getAllReviews()

  }, [])
console.log('vendorToEdit',vendorToEdit);
console.log('reviewEdit',reviewEdit);
  return (
    
          <div className='box'>
            <h2 style={{marginBottom:30}}>{reviewEdit?"Edit":"Add"} a Review</h2> 
              <input className='form-control' type="text" value={reviewName} onChange={(e)=>{setReviewName(e.target.value)}} placeholder="Enter Name" />
              <input className='form-control' type={"number"} value={reviewRating} onChange={(e)=>{setReviewRating(e.target.value)}} placeholder="Enter Rating" />
              <input className='form-control' type="text" value={reviewText} onChange={(e)=>{setReviewText(e.target.value)}} placeholder="Enter Review" />
              <img src={sendButton} style={{cursor:"pointer"}} onClick={()=>{
                if(reviewEdit)
                  editReview()
                else
                  postReview()}}
              />
              {!reviewEdit&& <h2 style={{marginBottom:30,marginTop:30}}>View All Reviews</h2> }
              <div className='row no-gutters my-5'>
              <div className='col-md-10'>
            {!reviewEdit&&reviewData.map((item,index)=>{

              if(true){
              
              return (<><div key={index} className='d-flex'>
              <div>
                <h3 className='name'>{item.attributes.Name}</h3>
                <div className='rbox' style={{width:"100%"}}>
                  <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    <img src={star} alt="" className='star' />
                    <p className='rating' style={{marginLeft:10,marginTop:20}}>{item.attributes.Rating}.0</p>
                    <p className='desc' style={{marginTop:20,marginLeft:10}}>3 days ago</p>
                  </div>
                  <div className='iBox' style={{display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"center",marginTop:20}}>
                  <p style={{color:"red",marginLeft:10,cursor:"pointer"}} onClick={()=>{deleteReview(item.id)}}><AiFillDelete/></p>
                  <p style={{color:"yellow",marginLeft:10,cursor:"pointer"}} 
                  onClick={()=>{
                    setReviewEdit(true);
                    setReviewEditId(item.id);
                    setReviewName(item.attributes.Name);
                    setReviewText(item.attributes.Description);
                    setReviewRating(item.attributes.Rating);
                  }}><AiFillEdit/></p>
                  </div>
                </div>
                <p className='desc'>{item.attributes.Description}</p>
              </div>
              
            </div>
            <hr/>
            </>
           
            
            )
              }
              else{
                return null
              }
            }
            )
            }
            
          </div>
          </div>

                
          </div>
  
     
  )
}

export default VendorAdmin;