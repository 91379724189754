import React,{useState} from 'react'
import Home from '../components/vendor/Home'
import Packages from '../components/vendor/Packages'
import Payment from '../components/vendor/Payment'
import Portfolio from '../components/vendor/Portfolio'
import Profile from '../components/vendor/Profile'
import SideNavbar from '../components/vendor/SideNav'
import TopNav from '../components/vendor/TopNav'
import VendorAdmin from "../pages/VendorAdmin";

function Vendor() {
  const [isLogo, setisLogo] = useState(false)
  const [status, setStatus] = useState(1);
  const changeStatus = (value) => {
    setStatus(value)
  }
  console.log(status);
  return (
    <div className='vendor'>
      <SideNavbar isLogo={isLogo} setisLogo={setisLogo} changeStatus={changeStatus} />
     <div className={`${isLogo ? 'bigMenu' : ''}`}>
      <TopNav />
      {status === 1 && <Home />}
      {status === 2 && <Profile  setStatus={setStatus}/>}
      {status === 3 && <Packages changeStatus={changeStatus} />}
      {status === 4 && <Portfolio />}
      {status === 5 && <Payment setStatus={setStatus}/>}
      {status === 6 && <VendorAdmin />}
     </div>
    </div>
  )
}

export default Vendor