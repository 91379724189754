import React, { useState, useEffect } from 'react'

import logo from '../../images/logo.png';
import check from '../../images/portfolio/check-mark.png';
import flower from '../../images/portfolio/flower.png';
import back from '../../images/appointment/back.png';
import monica from '../../images/login/login-img.png';
import pencil from '../../images/appointment/pencil.png';
import payment from '../../images/appointment/payment-btn.png';
import { PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import profilePic from '../../images/appointment/placeholder.jpeg';

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';



function StepSeven({ changeStatus, user, order, completed, packages }) {
  // console.table('packages', packages)
  const token = localStorage.getItem("token");
  // Formats time to AM and PM
  let timeString = user.time;
  let H = +timeString.substr(0, 2);
  let h = H % 12 || 12;
  let ampm = (H < 12 || H === 24) ? "AM" : "PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  console.log('packages', packages)
  const [picture, setpicture] = useState("")
  const [vendor, setVendor] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const getVendor = async (e) => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users');
      console.log('response', response.data)
      const filters = response.data.find(res => res.id === user.vendor_id)
      console.log("Filtered Vendor", filters)
      setpicture(filters.image)
      setVendor(filters)
      const category = user.service_name;
      console.log("Category......", category)
      const obj = filters && Object.keys(filters);
      const hh = obj.find(o => o === category)

      console.log('filters', filters[hh]);
      console.log('hh', hh);
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getVendor()
  }, [])

console.log('picture',picture)
console.log('vendor',vendor)


  const handleSubmit = async (event) => {
    event.preventDefault();
    toast.info("Please Wait...")
    await order();
    if (!stripe || !elements) {
      toast.error("Please wait until Stripe.js has loaded.", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://www.trippincreative.com/thankyou",
      },
    }).then(async (result) => {
      if (result.error) {
        toast.error(result.error.message, {
          position: "top-right",
          autoClose: 1000,
        });
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          toast.success("Payment successful", {
            position: "top-right",
            autoClose: 2000,
          });
          console.log(result.paymentIntent.charges.data[0].id)
          console.log(result.paymentIntent.charges.data[0].balance_transaction)
          console.log(result.paymentIntent.charges.data[0].amount)
          console.log(result.paymentIntent.charges.data[0].amount_refunded)
        }
        else {
          toast.error("Payment failed", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.paymentIntent.status);
    }
  };




  return (

    <div className='step-seven'>
      <div className='completed' style={{ borderTop: '8px solid #18BB78', width: `${completed}%`, marginTop: 0 }}> </div>
      <div className='container header'>
        <img onClick={token == null ? () => changeStatus(5) : () => changeStatus(4)} src={back} alt="" className='back' />
        <img src={logo} alt="" />
        <h2>Confirmation</h2>
      </div>
      <div className='packages'>
      <div className='potrait'>
        {picture === null ? <img className="monicas"  src={profilePic} /> : <img className="monicas" src={`https://api.trippincreatives.com${picture.url && picture.url}`} alt="" />}
        <h3 className='vendorImage'>{vendor.fullname}</h3>
        </div>
        <img src={flower} alt="" className='flower' />
        <div className='container'>
          <div className='row no-gutters'>
            <div className='col-md-4'>
              <h2>Contact Details</h2>
              <div className='d-flex align-items-center mt-4 mb-3'>
                <p className='package'>{user.fullname}</p>
              </div>
              <p>{user.email}</p>
              <p>{user.contact}</p>
              <h2>Service Details</h2>
              <div className='d-flex checklist'>
                <p className='package'>{user.date} , {timeString}</p>
                <img src={pencil} alt="" onClick={() => changeStatus(1)} />
              </div>
              <p>{user.duration}</p>
            </div>
            <div className='col-md-4'>
              <h2>Selected Package</h2>
              <div className='d-flex  align-items-center mt-4 mb-3'>
                <p className='package'>{packages.descriptionA ? 'Package A' : packages.descriptionB ? 'Package B' : packages.descriptionB ? 'Package C' : 'Package D'}
                &nbsp;(${packages.priceA ? packages.priceA : packages.priceB ? packages.priceB : packages.priceC ? packages.priceC : packages.priceD })
                </p>
                <img src={pencil} alt="" onClick={() => changeStatus(4)} />
              </div>
              <p>{packages.descriptionA ? packages.descriptionA : packages.descriptionB ? packages.descriptionB : packages.descriptionC ? packages.descriptionC : packages.descriptionD}
             </p>
            
              {packages.benefitAone || packages.benefitBone || packages.benefitCone || packages.benefitDone == '' ?
                <div className='d-flex checklist'>
                  {(packages.benefitAone || packages.benefitBone || packages.benefitCone || packages.benefitDone) && <img src={check} alt="" />}
                  <p>{packages.benefitAone ? packages.benefitAone : packages.benefitBone ? packages.benefitBone : packages.benefitCone ? packages.benefitCone : packages.benefitDone}</p>
                </div>
                : ""}

              {packages.benefitAtwo || packages.benefitBtwo || packages.benefitCtwo || packages.benefitDtwo == '' ?
                <div className='d-flex checklist'>
                  {(packages.benefitAtwo || packages.benefitBtwo || packages.benefitCtwo || packages.benefitDtwo) && <img src={check} alt="" />}
                  <p>{packages.benefitAtwo ? packages.benefitAtwo : packages.benefitBtwo ? packages.benefitBtwo : packages.benefitCtwo ? packages.benefitCtwo : packages.benefitDtwo}</p>
                </div>
                : ""}

              {packages.benefitAthree || packages.benefitBthree || packages.benefitCthree || packages.benefitDthree == '' ?
                <div className='d-flex checklist'>
                  {(packages.benefitAthree || packages.benefitBthree || packages.benefitCthree || packages.benefitDthree) && <img src={check} alt="" />}
                  <p>{packages.benefitAthree ? packages.benefitAthree : packages.benefitBthree ? packages.benefitBthree : packages.benefitCthree ? packages.benefitCthree : packages.benefitDthree}</p>
                </div>
                : ""}

              {packages.benefitAfour || packages.benefitBfour || packages.benefitCfour || packages.benefitDfour == '' ?
                <div className='d-flex checklist'>
                  {(packages.benefitAfour || packages.benefitBfour || packages.benefitCfour || packages.benefitDfour) && <img src={check} alt="" />}
                  <p>{packages.benefitAfour ? packages.benefitAfour : packages.benefitBfour ? packages.benefitBfour : packages.benefitCfour ? packages.benefitCfour : packages.benefitDfour}</p>
                </div>
                : ""}

            </div>
            <div className='col-md-4 total'>
           
              <p className='package'>Total Amount</p>
              <div className='d-flex justify-content-between heading mt-4'>
                <p>Service</p>
                <p>Qty</p>
                <p>Amt</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>Deposit</p>
                <p className='ml-3'>1</p>
                <p>SGD 50</p>
              </div>
              <hr />
              {/* <div className='d-flex justify-content-between'>
              <p className='heading'>Incl. GST</p>
              <p>{user.gst.toFixed(2)}</p>
            </div>
            <hr />
            <div className='d-flex justify-content-between'>
              <p  className='heading'>Total</p>
              <p>{user.total_price.toFixed(2)}</p>
            </div> */}

              {/* <img src={payment} alt="" className='payment' onClick={order} /> */}

              <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button style={{ background: "none", border: "none", cursor: "pointer" }} disabled={!stripe} type="submit" > <img src={payment} alt="" className='payment' /></button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default StepSeven







