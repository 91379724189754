/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import loginImg from '../images/login/login-img.png';
import loginBtn from '../images/login/login-btn.png';
import google from '../images/login/google.png';
import fb from '../images/login/fb.png';
import apple from '../images/login/apple.png';
import eye from '../images/login/eye.png';
import eyeSlash from '../images/login/eye-slash.png';
import craft from '../images/craft.png';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
function Signup({ history }) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [customer, setCustomer] = useState(true);
  const [vendor, setVendor] = useState(false);
  const [terms, setTerms] = useState(false);
  const fullname = useRef();
  const confirmPassword = useRef();
  const email = useRef();
  const password = useRef();

  const signupFunction = async (e) => {
    e.preventDefault()
    if (fullname.current.value === '' || email.current.value === '' || password.current.value === '') {
      return toast.error('All fields required', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    // if (fullname.current.value.split(" ").length < 3)
    //   return toast.error('Please Enter Full Name');
    if (!email.current.value.includes('@')) {
      return toast.error('please enter valid email address', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    if (!customer && !vendor) return toast.error('Please select type')
    if (!terms) return toast.error('Please Check Terms and Policies')

    //new code
    var data = JSON.stringify({
      "email": email.current.value,
      "fullname": fullname.current.value,
      "username": fullname.current.value,
      "password": password.current.value,
      "type": customer ? "customer" : "vendor"
    });

    var config = {
      method: 'post',
      url: 'https://api.trippincreatives.com/api/auth/local/register',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        if (response.data.user.type === 'customer') {
          response.data.user.fullname === '' || response.data.user.phone === '0' || response.data.user.phone === null ? history.push('/profile') : history.push('/')
        }
        else {
          history.push('/vendor')
        }
      })
      .catch(function (e) {
        console.log(e);
        toast.error(e.response.data.error.message, {
          position: "top-right",
          autoClose: 2000,
          });
        console.log(e.response.data.error.message)
      });

    //new code 

  }
  return (
    <div className='login'>
      <ToastContainer />

      <img src={craft} alt="" className='craft' />
      <div className='left'>
        <Link className="navbar-brand py-4" to="/">
          <img src={logo} alt="" />
        </Link>
        <img src={loginImg} alt="" />
      </div>
      <div className='center'>
        <div className='right'>
          <h1>Sign Up</h1>
          <form>
            <div className='d-flex align-items-baseline'>
              <input defaultChecked={customer} onChange={(e) => {
                setCustomer(true)
                setVendor(false)
              }} type="radio" name="gender" value="" />
              <label htmlFor="" className='mr-3'>I am a Customer</label>
              <input onChange={(e) => {
                setCustomer(false)
                setVendor(true)
              }} type="radio" name="gender" />
              <label htmlFor="">I am a Vendor</label>
            </div>
            <div className="form-group">
              <label>Full Name</label>
              <input ref={fullname} type="text" className="form-control" placeholder="Enter Name" />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input ref={email} type="email" className="form-control" placeholder="Enter email" />
            </div>
            <div className="form-group position-relative">
              <label>Password</label>
              <input ref={password} type={isRevealPwd ? "text" : "password"} className="form-control" placeholder="Enter Password" />
              <img
                className='eye'
                src={!isRevealPwd ? eyeSlash : eye}
                onClick={() => setIsRevealPwd(prevState => !prevState)}
              />
            </div>
            <div className='d-flex'>
              <input type="checkbox" onChange={(e) => { setTerms(e.target.checked) }} /><p className='ml-3'>By creating an account, you accept the Terms of Service and Privacy Policy.</p>
            </div>
            <button onClick={signupFunction} className='btn'>Signup</button>
            <div className='text-center my-4'>
              {/* <p>or continue with</p> */}
              {/* <div className='d-flex justify-content-center my-4' style={{ cursor: 'pointer' }}>
                <img src={google} alt="" onClick={() => window.location.href = `https://api.trippincreatives.com/api/connect/google`} />
                <img src={fb} alt="" onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/connect/facebook`} />
              </div> */}
              <p>Don’t have an account?</p>
              <Link to='/login' className='green'>Login Now</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signup