import React from 'react'
import Carousel from 'react-grid-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import loreal from '../../images/loreal.png';
import globe from '../../images/globe.png';
import singapore from '../../images/singapore.png';
import kebun from '../../images/kebun.png';
import piazza from '../../images/piazza.png';
import ethoz from '../../images/ethoz.png';
import intercontinental from '../../images/intercontinental.png';
import PropTypes from 'prop-types'
import styled from 'styled-components'

function Community() {

  const ButtonWrapper = styled.div`
  @media screen and (max-width: ${({ mobileBreakpoint }) =>
      mobileBreakpoint}px) {
    display: none;
  }
`

const Button = styled.span`
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  transition: opacity 0.25s;
  left: ${({ type }) => (type === 'prev' ? '5px' : 'initial')};
  right: ${({ type }) => (type === 'next' ? '5px' : 'initial')};
  &:hover {
    opacity: 1;
  }
  &::before {
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ type }) =>
      type === 'prev'
        ? 'translate(-25%, -50%) rotate(-135deg)'
        : 'translate(-75%, -50%) rotate(45deg)'};
  }
`

const ArrowButton = ({
  type,
  mobileBreakpoint = 1,
  hidden = false,
  CustomBtn,
  onClick
}) => (
  <ButtonWrapper
    mobileBreakpoint={mobileBreakpoint}
    hidden={hidden}
    onClick={onClick}
  >
    {CustomBtn ? (
      typeof CustomBtn === 'function' ? (
        <CustomBtn />
      ) : (
        CustomBtn
      )
    ) : (
      <Button type={type} />
    )}
  </ButtonWrapper>
)

ArrowButton.propTypes = {
  type: PropTypes.oneOf(['prev', 'next']).isRequired,
  mobileBreakpoint: PropTypes.number,
  hidden: PropTypes.bool,
  CustomBtn: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType
  ]),
  onClick: PropTypes.func.isRequired
}

  return (
    <div className='community'>
      <div className='container'>
        <img src={globe} alt="" className="globe" />
        <h2>A Global Community</h2>
        {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p> */}
        <div className='d-flex justify-content-around text-center my-5'>
          <div>
            <h2>6</h2>
            <p>Countries</p>
          </div>
          <div>
            <h2>1320</h2>
            <p>Clients</p>
          </div>
          <div>
            <h2>21</h2>
            <p>Professionals</p>
          </div>
        </div>
        <div className='d-flex mb-5'>
          <p>Some brands I’ve worked with</p><hr />
        </div>

        {/* <OwlCarousel className=" owl-theme" loop  margin={1} nav>
        
          <div className="item"> <img src={loreal} alt=" " /></div>
          <div className="item"> <img src={singapore} alt="" /> </div>
          <div className="item"> <img src={kebun} alt="" /></div>
          <div className="item"> <img src={piazza} alt="" /></div>
          <div className="item"> <img src={intercontinental} alt="" /></div>
          <div className="item"> <img src={ethoz} alt="" /></div>
          
      
        </OwlCarousel> */}

<div className='slider'>
      
          <Carousel 
            
          cols={3} rows={1} 
            loop={true}
            autoplay={2000}
            infinite={true}
           
            responsiveLayout={[
              {
                breakpoint: 1200,
                cols: 2
              },
              {
                breakpoint: 990,
                cols: 2,
                hideArrow: 'true',
                scrollSnap: 'true',
              }
            ]}
            mobileBreakpoint={670}
          
            
          >
            <Carousel.Item >
              <img className="photos" width="100%" src={loreal} alt="" />
            </Carousel.Item>

            <Carousel.Item >
              <img className="photos" width="100%"  src={singapore} alt="" />
            </Carousel.Item>

            <Carousel.Item >
              <img className="photos" width="100%" src={kebun} alt="" />
            </Carousel.Item>

            <Carousel.Item >
              <img className="photos" width="100%"  src={piazza} alt="" />
            </Carousel.Item>

            <Carousel.Item >
              <img className="photos" width="100%"  src={intercontinental} alt="" />
            </Carousel.Item>

            <Carousel.Item >
              <img className="photos" width="100%"  src={ethoz} alt="" />
            </Carousel.Item>
            
          </Carousel>
          </div>
        </div>
      </div>
  
  )
}



export default Community

