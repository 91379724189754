import React, { useEffect } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import photographyOne from '../../images/photography-one.png';
import photographyTwo from '../../images/photography-two.png';
import photographyThree from '../../images/photography-three.png';

function Photography(props) {
  // console.log("props", props);
 

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    }
  };

 const images =  props.carouselpic.map((image) => {
  return (
<img src={`https://api.trippincreatives.com${image.attributes.url}`} alt="image" />
    
  )
})

/*
const names =  props.carouselpic.map((name) => {
  return (
    <h2 className="mb-4 pb-3">Lorem</h2>
  )
})
*/

  return (
    <>
    <div className="photography">
      <div className='container'>
        <div className='row no-gutters'>
        {/* {names} */}
          <Carousel responsive={responsive} infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            className="container">
                {images}
         </Carousel>
        </div>
      </div>
    </div>
  

    </>
  )
}

export default Photography