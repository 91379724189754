import React from 'react'
import moment from 'moment';
import blogImg from '../../images/blog/blog.png';
import arrowRight from '../../images/blog/arrow-right.png';
import { withRouter } from 'react-router-dom';

function Hero({history,blog}) {
  console.log("Heroblog",blog);
  const url = "https://api.trippincreatives.com";
  return (
    <div className='hero'>
      <div className='container'>
     {blog && <div className='row no-gutters'>
        <div className='col-md-6'>
          <p>Posted on {moment(blog.attributes.publishedAt).format('Do MMM YY')}</p>
          <h1>{blog.attributes.title}</h1>
          <p className='mt-2'>{blog.attributes.description.slice(0, 200)}</p>
          <div style={{cursor:'pointer'}} onClick={()=> history.push(`/blog/${blog.id}`)} className='d-flex align-items-center'>
          <p className='mr-3 mb-0 readTxt'>Read More</p>
          <img src={arrowRight} alt="" /></div>
          </div>
         <div className='col-md-6 play'>
          <img src={`${url}${blog.attributes.image.data.attributes.url}`}  alt="" className='img-fluid'/>
         </div>
      </div>  }
      </div>
    </div>
  )
}

export default withRouter(Hero)