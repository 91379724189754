/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { useEffect, useState, useNavigate, useHistory } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { MdLogin } from 'react-icons/md';
import { MdLogout } from 'react-icons/md';
import { IoIosCall } from "react-icons/io";
import { TbClipboardList } from "react-icons/tb";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter, Link } from 'react-router-dom';
import logo from '../images/logo.png';
import call from '../images/call.png';
import message from '../images/message.png';
import account from '../images/account.png';
import myorder from '../images/MyOrders.png'
import order from '../images/order.png'
import footer from '../components/footer';
import { FiPhone } from "react-icons/fi";
import profilePic from '../images/appointment/placeholder.jpeg';


const parseJwt = (token,) => {

  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }

};

const auth = localStorage.getItem("isAuthenticated");

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pic: ' ',
      auth: false,
    }
  }


  logout() {
    localStorage.clear();
    window.location.href = '/login';
  }
  fetchData = async () => {
    // Getting profile picture

    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      };
      const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config)
      this.setState({ pic: response.data.image.formats.thumbnail.url })

      console.log('pic', response.data.image.formats.thumbnail.url)
    } catch (error) {
      //toast.error(error.response.data.error.message);
    }
  }

  authinticate = () => {
    if (this.state.auth) {
      console.log('this.state.auth', this.state.auth);
      this.props.history.push("/appointment");
    } else {
      this.props.history.push("/login");
      // toast.error("Please Login First")
    }
  }
 

  componentDidMount() {
    this.fetchData();
    this.setState({ auth: localStorage.getItem("isAuthenticated") });
  }


  render() {
    const decode = parseJwt(localStorage.getItem('token'));
    const isAuth = decode && decode.exp * 1000 < Date.now();
    console.log('isAuth', isAuth);
    try {
      var user = JSON.parse(localStorage.getItem('user'));
    }
    catch (e) {
      console.log("Error :", e)
    }
    // var user = JSON.stringify(localStorage.getItem('user'));

    const array = [
      { name: 'Videography', },
      { name: 'Photography', },
      { name: 'AerialDrones', },
      { name: 'LiveStream', },
      { name: '360VirtualTours' },
    ];

    console.log("props.....", this.props);

    const navigation = [
      { img: call, href: '#getintouch', current: false },

    ]





    return (
      <nav className="navbar sticky-top navbar-fixed-top navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav left  navbar-nav">
            <li className="nav-item">
              <Link to="/home">Home</Link>
            </li>
            {/* Seervice Dropdown */}

            <li className="nav-item">
              <div className="dropdown">

                <Link className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</Link>
                <div className="menu dropdown-menu drop-menu " aria-labelledby="dropdownMenuButton">
                  {array.map((arr, index) => (
                    <a className="font dropdown-item"
                      onClick={() => { this.props.history.push({ pathname: `/services/${arr.name}`, state: { id: index } }) }}>
                      {arr.name}</a>


                  ))}
                </div>
              </div>
            </li>

            <li className="nav-item">
              <Link to="/portfolio">Portfolios</Link>
            </li>
            <li className="nav-item">
              <Link to="/about">About Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/blog">Blogs</Link>
            </li>

          </ul>
          <ul className="right nav navbar-nav ml-auto">
            <li className=" nav-item hide">


              {navigation.map((item) => (

                <a href={item.href} className="navbar-brand scroll" >

                  <img className="call " src={item.img} />
                </a>


              ))}

            </li>

            {/* <li className="nav-item hide ">
              <img src={message} alt="" />
            </li> */}

            {(user === null || isAuth === null || user.type === 'vendor') && <li className="nav-item dropdown hide">
              <a className="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src={account} alt="" />
              </a>
              <div className="dropdown-menu menu" aria-labelledby="navbarDropdown">
                <div className="menu_1 ">

                  <Link className="dropdown-item itm" to="/login"> <i className="icon"><MdLogin /></i> Login</Link>

                </div>
                <div className="menu_1 ">
                  {navigation.map((item) => (

                    <a className="dropdown-item" href={item.href}>  <i className="icon"><IoIosCall /></i> Contact Us</a>

                  ))}
                </div>

                {/* <a className="dropdown-item" href="#">Something else here</a> */}
              </div>
            </li>}
            {user && user.type === 'customer' && !isAuth && <li className="nav-item dropdown hide">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                {this.state.pic === " " ? <img className="rounded-circle z-depth-0" height="35" src={profilePic} /> : <img className="rounded-circle z-depth-0" height="35" src={`https://api.trippincreatives.com${this.state.pic && this.state.pic}`} alt="" />}



              </a>

              <div className="dropdown-menu menu " aria-labelledby="navbarDropdown">
                <div className="menu_1 ">

                  {/* <i className="icon"><CgProfile /></i>  */}

                  <Link className="dropdown-item" to="/profile"><i className="icon"><CgProfile /></i> Profile</Link>
                </div>
                <div className="menu_1">
                  {/* <img className="icons  " src={order} alt=""/> */}
                  <Link className="dropdown-item tag" to="/orders"><i className="icon"><TbClipboardList /></i>My Orders</Link>

                </div>
                <div className="menu_1 ">
                  {/* <i className="icon"><MdLogout/></i> */}
                  <Link onClick={this.logout} className="dropdown-item"><i className="icon"><MdLogout /></i> Logout</Link>

                </div>

              </div>

            </li>}
            <li className="nav-item last-nav">
              <button onClick={this.authinticate} className='btn'>Book an Appointment</button>
            </li>
            {/* {type === 'public' && this.renderLoggedInMenu()} */}
          </ul>
        </div>
      </nav>
    );
  }

}

export default withRouter(Navbar);
