import React from 'react'

import toast from '../../images/toast.png';
import calendar from '../../images/calendar.png';
import wallet from '../../images/wallet.png';
import cameraTwo from '../../images/camera-two.png';
import arrow from '../../images/arrow.png';
import arrowTwo from '../../images/arrow-two.png';
import arrowThree from '../../images/arrow-three.png';

function Works(props) {
  return (
    <div className='works'>
      <div className='container mt-5 px-5'>
        <h2>How It Works</h2>
        <div className='row d-flex align-items-center'>
          <div className='col-md-1 mr-4'>
          <img src={ toast } alt=""/>
          </div>
          <div className='col-md-4'>
            <h3 className=' text-center'><span>01.</span>{props.userdata.Section1howitworks}</h3>
            <p>{props.userdata.Section1howitworksdesc}</p>
          </div>
          <div className='col-md-2 '>
            <img src={ arrow } alt="" className='arrow'/>
          </div>
        </div>

        <div style={{ marginTop: '2rem',marginBottom:'2rem'}} className='row d-flex align-items-center'>
        <div className='col-md-2 offset-md-4'>
            <img src={ arrowTwo } alt="" className='arrow'/>
        </div>
          <div className='col-md-1 mr-4'>
          <img src={ calendar } alt=""/>
          </div>
          <div className='col-md-4'>
            <h3 className=' text-center'><span>02.</span>{props.userdata.Section2howitworks}</h3>
            <p>{props.userdata.Section2howitworksdesc}</p>
          </div>
         
        </div>

        <div style={{ marginTop: '2rem',marginBottom:'2rem'}} className='row d-flex align-items-center'>
          <div className='col-md-1 mr-4'>
          <img src={ wallet } alt=""/>
          </div>
          <div className='col-md-4'>
            <h3 className=' text-center'><span>03.</span>{props.userdata.Section3howitworks}</h3>
            <p>{props.userdata.Section3howitworksdesc}</p>
          </div>
          <div style={{    marginTop: '20px' }} className='col-md-2 '>
            <img src={ arrowThree } alt="" className='arrow'/>
          </div>
        </div>

        <div className='row d-flex align-items-center'>
          <div className='col-md-1 mr-4 offset-md-6'>
          <img src={ cameraTwo } alt=""/>
          </div>
          <div className='col-md-4'>
            <h3 className='md:text-left text-center'><span>04.</span>{props.userdata.Section4howitworks}</h3>
            <p>{props.userdata.Section4howitworksdesc}</p>
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default Works