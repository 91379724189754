/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import logo from '../../images/logo.png';
import check from '../../images/portfolio/check-mark.png';
import flower from '../../images/portfolio/flower.png';
import back from '../../images/appointment/back.png';
import monica from '../../images/login/login-img.png';
import axios from 'axios';
import profilePic from '../../images/appointment/placeholder.jpeg';


function StepFive({ changeStatus, handlePackage, user, completed }) {
  // const [packageData, setpackageData] = useState([])
  // const [catee, setCat] = useState('videopackage')
    console.log('user',user);

  const token = localStorage.getItem("token");
  const [vendor, setVendor] = useState('');
  const [catee, setCat] = useState('')
  const [picture, setpicture] = useState("")

  // console.log(token);

  const packageJSON={
    '360 Virtual Tours':"vrpackage",
    'Videography':"videopackage",
    'Photography':"photographypackage",
    'Copywriting':"copywritingpackage",
    'Aerial Drones':"dronespackage",
    'Live Stream':"streampackage"
  }
  //  console.log('packageJSON', packageJSON);


  const getVendor = async (e) => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users');
      console.log('response',response)
      //const cat = user.category;
      const filters = response.data.find(res => res.id == user.vendor_id)
      console.log("Filtered Vendor", filters)
      setVendor(filters)
      setpicture(filters.image)
      const category = user.service_name;
      console.log("Category......",category)
      const obj = filters && Object.keys(filters);
      console.log('obj',obj);
      const hh = obj.find(o => o == category)
      setCat(hh)
      // console.log('hhhhhhhhh',hh);
      
      console.log('filters',filters[hh]);
      console.log('catee',catee[hh]);
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getVendor()
  }, [])
  // console.log('picture',picture)
  console.log('vendor',vendor)

  return (
    <div className='step-five'>
      <div className='completed' style={{ borderTop: '8px solid #18BB78', width: `${completed}%`, marginTop: 0 }}> </div>
      <div className='container header'>
        <img onClick={() => changeStatus(3)} src={back} alt="" className='back' />
        <img src={logo} alt="" />
        <h2>Would you like to consider going for a package?</h2>
      </div>
      <div className='packages'>
        <div className='potrait'>
        {picture === null ? <img className="monicas"  src={profilePic} /> : <img className="monicas" src={`https://api.trippincreatives.com${picture.url && picture.url}`} alt="" />}
        <h3 className='vendorImage'>{vendor.fullname}</h3>
        </div>

        <img src={flower} alt="" className='flower' />
        <div className='container'>
          <h2>Packages</h2>
          {/* {condition()} */}
          {vendor && vendor[catee] ?
            <div className='row no-gutters'>
              {(vendor[catee][0].descriptionA && vendor[catee][0].priceA) && Object.keys(vendor[catee][0]).length !== 0 && <div className='col-md-4'>
                <p className='package'>Package A</p>
                <p>{vendor[catee][0]?.descriptionA}</p>

                {vendor[catee][0].benefitAone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAone}</p>
              </div>
              : ""}

            {vendor[catee][0].benefitAtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAtwo}</p>
              </div>
              : ""}

            {vendor[catee][0].benefitAthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAthree}</p>
              </div>
              : ""}

            {vendor[catee][0].benefitAfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][0].benefitAfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][0]?.benefitAfour}</p>
              </div>
              : ""}

                <p className='price font-weight-bold'>${vendor[catee][0]?.priceA}</p>
                <button className={user.package == 'Package A' ? 'btn green' : 'btn'}
                  onClick={() => {handlePackage("Package A", vendor[catee][0]?.priceA, 13.33333 / 100 * vendor[catee][0]?.priceA, vendor[catee][0]?.priceA + 13.33333 / 100 * vendor[catee][0]?.priceA, vendor[catee][0]); ;}}
                >{user.package == 'Package A' ? 'Selected' : 'Select'}</button>
               
              </div>}

              {(vendor[catee][1].descriptionB&&vendor[catee][1].priceB) && Object.keys(vendor[catee][1]).length !== 0 && <div className='col-md-4'>
                <p className='package'>Package B</p>
                <p>{vendor[catee][1]?.descriptionB}</p>
                
                {vendor[catee][1].benefitBone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBone}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBtwo}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBthree}</p>
              </div>
              : ""}

            {vendor[catee][1].benefitBfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][1].benefitBfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][1]?.benefitBfour}</p>
              </div>
              : ""}

                
                <p className='price font-weight-bold'>${vendor[catee][1]?.priceB}</p>
                <button className={user.package == 'Package B' ? 'btn green' : 'btn'}
                  onClick={() =>{ handlePackage("Package B", vendor[catee][1]?.priceB, 13.33333 / 100 * vendor[catee][1]?.priceB, vendor[catee][1]?.priceB, vendor[catee][1]); }}>{user.package == 'Package B' ? 'Selected' : 'Select'}</button>
              
              </div>}
              {(vendor[catee][2].descriptionC&&vendor[catee][2].priceC) && Object.keys(vendor[catee][2]).length !== 0 && <div className='col-md-4'>
                <p className='package'>Package C</p>
                <p>{vendor[catee][2]?.descriptionC}</p>
               
                {vendor[catee][2].benefitCone !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCone != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCone}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCtwo !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCtwo != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCtwo}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCthree !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCthree != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCthree}</p>
              </div>
              : ""}

            {vendor[catee][2].benefitCfour !== '' ?
              <div className='d-flex checklist'>
                {vendor[catee][2].benefitCfour != "" && <img src={check} alt="" />}
                <p>{vendor[catee][2]?.benefitCfour}</p>
              </div>
              : ""}
                
                <p className='price font-weight-bold'>${vendor[catee][2]?.priceC}</p>
                <button className={user.package == 'Package C' ? 'btn green' : 'btn'} onClick={() =>{ handlePackage("Package C",
                  vendor[catee][2]?.priceC, 13.33333 / 100 * vendor[catee][2]?.priceC, vendor[catee][2]?.priceC + 13.33333 / 100 * vendor[catee][2]?.priceC, vendor[catee][2]);}}>{user.package == 'Package C' ? 'Selected' : 'Select'}</button>
              </div>}
            </div> :
            <h5>No Package Added Yet. Please Select a Vendor with Packages Available</h5>}

        </div>

      </div>
    {/* {(vendor && vendor[catee]) &&<button onClick={token == null ? () => changeStatus(5) : () =>  } className='btn nxt'>Next</button>} */}

    </div>
  )
}

export default StepFive