import React, { useState, useLayoutEffect } from 'react'
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
// import { withRouter, Link } from 'react-router-dom';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import logo from '../../images/logo.png';
import home from '../../images/vendor/home.png';
import profile from '../../images/vendor/profile.png';
import packages from '../../images/vendor/package.png';
import payment from '../../images/vendor/payment.png';
import portfolio from '../../images/vendor/portfolio.png';

const menus = [
  { name: 'home', image: home },
  { name: 'profile', image: profile },
  { name: 'My Services', image: packages },
  { name: 'portfolio', image: portfolio },
  { name: 'payment', image: payment },
  { name: 'vendorAdmin', image: portfolio },
]
function SideNavbar({ isLogo, setisLogo, changeStatus }) {
  const [size, setSize] = useState([0]);

  useLayoutEffect(() => {
    // Gets the Windows width and size
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <SideNav
        onSelect={(selected) => {
          console.log("hi");
        }}
      >
        {size >= 768 &&
          <SideNav.Toggle onClick={() => {
            setisLogo(!isLogo)
          }} />
        }
        <SideNav.Nav defaultSelected="home">
          <img src={logo} alt="" className={`${!isLogo ? 'logo none' : 'logo'}`} />
          {menus.map((m, i) => (
            <NavItem eventKey={m.name} onClick={() => changeStatus(i + 1)}>
              <NavIcon>
                <img src={m.image} alt="" />
              </NavIcon>
              <NavText>
                {m.name[0].toUpperCase() + m.name.slice(1)}
              </NavText>
            </NavItem>
          ))}
        </SideNav.Nav>
      </SideNav>
    </>
  )
}

export default SideNavbar