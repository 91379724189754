/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import caret from '../images/caret-left.png';
import mail from '../images/mail.png';
import phone from '../images/phone.png';
import monica from '../images/portfolio/monica.png';
import tape from '../images/portfolio/tape.png';
import check from '../images/portfolio/check-mark.png';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import profilePic from '../images/appointment/placeholder.jpeg';


function OrderDetails(props) {
  console.log('props', props);
  let history = useHistory();
  // console.log(props.match.params.id);
  const [data, setdata] = useState([])
  const [detail, setDetail] = useState([0])
  const [picture, setpicture] = useState("")

  const packageJSON = {
    "vrpackage": '360 Virtual Tours',
    "videopackage": 'Videography',
    "photographypackage": 'Photography',
    "copywritingpackage": 'Copywriting',
    "dronespackage": 'Aerial Drones',
    "streampackage": 'Live Stream',
    "funeralpackage": 'Funeral',
    "weddingpackage": 'Wedding',
    "birthdaypackage": 'Birthday',
    "potraitpackage": 'Portrait'
  }


  const packageArrayIndex = {

    "Package A": 0,
    "Package B": 1,
    "Package C": 2
  }
  const packageArrayIndexDetails = {
    "Package A": {
      "D": "descriptionA",
      "B1": "benefitAone",
      "B2": "benefitAtwo",
      "B3": "benefitAthree",
      "B4": "benefitAfour",
      "P": "priceA"
    },
    "Package B": {
      "D": "descriptionB",
      "B1": "benefitBone",
      "B2": "benefitBtwo",
      "B3": "benefitBthree",
      "B4": "benefitBfour",
      "P": "priceB"
    },
    "Package C": {
      "D": "descriptionC",
      "B1": "benefitCone",
      "B2": "benefitCtwo",
      "B3": "benefitCthree",
      "B4": "benefitCfour",
      "P": "priceC"
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const fetchData = async () => {
      try {
        let responseData = await axios.get(`https://api.trippincreatives.com/api/orders/${props.match.params.id}`, config);
        setdata(responseData.data.data);
        console.log("This is the order data", responseData.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
    getVendor()
  }, [])



  const getVendor = async () => {
    try {
      const response = await axios.get('https://api.trippincreatives.com/api/users?populate=%2A');

      setDetail(response.data)
    }
    catch (e) {
      console.log(e)
    }
  }
  const timeChanger = (value) => {
    let timeString = value;
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    let ampm = (H < 12 || H === 24) ? "AM" : "PM";
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString
  }
  

  const updateData = async () => {
    var data = JSON.stringify({
      "data": {
        "status": "Cancelled"
      }
    });

    var config = {
      method: 'put',
      url: 'https://api.trippincreatives.com/api/orders/' + props.match.params.id,
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        history.push('/orders');
        toast.success("Canceled Successfully..!", {

               position: "top-right",
               autoClose: 2000,
             });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [vendorData, setVendorData] = useState([])

  const [vendorOfTheOrder, setVendorOfTheOrder] = useState(null)

  const getUserData = async () => {
    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/users',
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };
    await axios(config)
      .then(function (response) {
        console.log("All User Data", response.data);
        setVendorData(response.data.filter(u => u.type === "vendor"))
        getVendorDetails()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    getVendorDetails()

  }, [vendorData, data])

  const getVendorDetails = () => {

    if (!data || vendorData.length === 0)
      return

    try {
      for (let i = 0; i < vendorData.length; i++) {
        if (vendorData[i].id == data.attributes.vendor_id) {
          console.log("Vendor Of the Order", vendorData[i])
          setVendorOfTheOrder(vendorData[i])
          setpicture(vendorData[i].image)
          return
        }
      }
      return
    } catch (error) {
      return
    }
  }

  // function images(){

  console.log('data', data);
  console.log('picture', picture);

  function clicked() {
    window.location = "mailto:{vendorOfTheOrder.email}";
    // window.location.href = "mailto:{vendorOfTheOrder.email}"
  }

console.log('dataaaaaaaa',data.attributes);
  return (

    <div className='orders-details row no-gutters'>

      <ToastContainer />

      <Navbar />
      <div className='container font-sans'>
        <div className='d-flex align-items-center cursor-pointer'>
          <img src={caret} alt="" />
          <p className='mb-0 ml-2 ' style={{ cursor: 'pointer' }} onClick={() => history.push('/orders')}>Go Back</p>
        </div>
        <div className='order'>
          <p className='text'>Order ID: {data.id} </p>
          <hr />
          <div className='row no-gutters'>
            <div className='col-md-6'>
              <h2 className='font-sans'>Service Details</h2>
              <p className='bold'>{data.attributes && data.attributes.date} , {data.attributes && timeChanger(data.attributes.time)}</p>
              <p>{data.attributes && data.attributes.duration}</p>
              <h2 className='font-sans'>Talent Details</h2>

              <div className='white-bg'>
                <img src={tape} alt="" className='tape' />
                {picture === null ? <img className="monica" src={profilePic} /> : <img className="monica" src={`https://api.trippincreatives.com${picture.url && picture.url}`} alt="" />}

                <p>{vendorOfTheOrder && vendorOfTheOrder.fullname}</p>
              </div>
              <p className='bold'>Contact Info</p>
              <div className='flex sm:flex-row flex-col justify-between sm:my-0 my-3'>
                <p>{vendorOfTheOrder && vendorOfTheOrder.email}</p>
                <a style={{ cursor: 'pointer', }} href={`mailto:{vendorOfTheOrder&&vendorOfTheOrder.email}`} className='d-flex align-items-center'><p style={{ color: 'white' }} className='text'>Send Email</p><img src={mail} alt="" /></a>
              </div>
              <div className='flex sm:flex-row flex-col justify-between'>
                <p>{vendorOfTheOrder && vendorOfTheOrder.phone}</p>
                <a style={{ cursor: 'pointer' }} className='d-flex align-items-center' href={`tel:{vendorOfTheOrder.phone}`}><p style={{ color: 'white' }} className='text'>Contact</p><img style={{ cursor: 'pointer' }} src={phone} alt="" /></a>
              </div>
            </div>
            <div className='col-md-6'>
              <h2>Selected Package</h2>
              <p className='bold'>{data.attributes && data.attributes.package}</p>
              <p>{(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["D"]]}</p>

              {(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B1"]] !== "" ?
                <div className='d-flex checklist'>
                  <img src={check} alt="" />
                  <p>{(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B1"]]}</p>
                </div>
                : ""}

              {(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B2"]] !== "" ?
                <div className='d-flex checklist'>
                  <img src={check} alt="" />
                  <p>{(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B2"]]}</p>
                </div>
                : ""}

              {(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B3"]] !== "" ?
                <div className='d-flex checklist'>
                  <img src={check} alt="" />
                  <p>{(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B3"]]}</p>
                </div>
                : ""}

              {(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B4"]] !== "" ?
                <div className='d-flex checklist'>
                  <img src={check} alt="" />
                  <p>{(data.attributes && vendorOfTheOrder) && vendorOfTheOrder[data.attributes.service_name][packageArrayIndex[data.attributes.package]][packageArrayIndexDetails[data.attributes.package]["B4"]]}</p>
                </div>
                : ""}
              <p className='bold mt-5'>Total Amount</p>
              <div className='total'>


                <table className="table">
                  <thead>
                    <tr>

                      <th scope="col"><p>Service</p></th>
                      <th scope="col"><p>Qty</p></th>
                      <th scope="col"><p className="amt">Amt</p></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td > <p>{data.attributes && packageJSON[data.attributes.service_name]}</p></td>
                      <td ><p className="qty">1</p></td>
                      <td><p className="gst">{data.attributes && data.attributes.price}</p></td>
                    </tr>
                    <td><p>Deposit Amount</p></td>
                    <td><p></p></td>
                    <td><p>SGD 50</p></td>

                    {/* <tr>
                      <td  colspan="2"><p className="gsts">Incl. GST</p></td>
                      <td  > <p className="gst">{data.attributes && data.attributes.gst}</p></td>
                    </tr>
                    <tr className='head'>
                      <td colspan="2"><p className='heading'>Total</p></td>
                      <td > <p className="gst">{data.attributes && data.attributes.total_price}</p></td>
                    </tr> */}
                  </tbody>
                </table>


              </div>
            </div>
          </div>

          {data.attributes?.status !== "Cancelled" && <button onClick={(e) => updateData(e)} className='btn btn-danger'>Cancel Booking</button>}

        </div>
      </div>
      <Footer />


    </div>
  )
}

export default OrderDetails