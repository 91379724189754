import React,{useState,useEffect} from 'react'

import profilePic from '../../images/profile/profile.png';
import plus from '../../images/vendor/plus.png';
import lock from '../../images/profile/lock.png';
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';

function Payment({history}) {
  const [user, setUser] = useState({fullname:"",bankname:"",type:"",number:""})
  const [person, setperson] = useState(JSON.parse(localStorage.getItem("user")))
  const [token, settoken] = useState(localStorage.getItem("token"))
  const [file, setFile] = useState();
  const [imageId, setimageId] = useState(0)
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    setperson(JSON.parse(localStorage.getItem("user")))
    settoken(localStorage.getItem("token")) 
    const fetchQrCode = async () => {
      // Getting profile picture
      try{
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/qrcodes?populate=*`,config)
        // setpicture(response.data.image.formats.thumbnail.url)
        // setimageId(response.data.image.id)
        console.log(response.data)
       }catch(error){
          //toast.error(error.response.data.error.message);
        }
      }
      fetchQrCode()
      }, [])

  useEffect(() => {
    const fetchData = async () => {
      // Getting profile picture
      try{
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/users/id`,config)
        setUser({...user,fullname:response.data.fullname,bankname:response.data.bankname,type:response.data.accounttype,number:response.data.accountno})
        console.log(response);
        
       }catch(error){
          //toast.error(error.response.data.error.message);
        }
      }
        fetchData()
    
  }, [])
  const handleChange =  e => {
    setUser({...user,[e.target.name]:e.target.value})
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` }
    };
  const paymentFunction = async (e) => {
    e.preventDefault()
    if(user.fullname === ''|| user.bankname === ''|| user.type === '' || user.number=== '') {
    return toast.error('All fields required');
    }
    try {   
    const response = await axios.put(`https://api.trippincreatives.com/api/users/${person.id}`,{
      bankname: user.bankname,
      accounttype: user.type,
      accountno: user.number,
    },config)
    toast.success('Your Bank Details Are Saved') }
    catch (e) {
      toast.error(e.response.data.error.message)
      // console.log(e.response.data.error.message)
    }
  }
  console.log(user)
  
  const uploadQR=async ()=>{
    let formData = new FormData();
    formData.append("files", file);
    console.log(file);
    try {
      if (file) {
        // If we have a file
        const response = await axios.post("https://api.trippincreatives.com/api/upload", formData);
        console.log("QR Image ID",response.data[0].id)
      }
    }
      catch(e)
      {
console.log(e)
      }
  }

  useEffect(()=>{
    if(file)
    uploadQR()
  },[file,fileName])



  const saveFile = (e) => {
    e.preventDefault()
    const files = e.target.files[0];
    if(files === undefined) {
        console.log("undefined")
    } else if(files.type === "image/png" || files.type === "image/jpeg") {
      setFile(e.target.files[0]);
     setFileName(e.target.files[0].name);
    } else {   
      toast.success("File does not support. You must use .png or .jpg ", {
        position: toast.POSITION.TOP_RIGHT
      });
    
    }
}

  return (
    <>
    <div className='payment row no-gutters'>
    <div className='container'>
      <div className='row no-gutters'>
      <div className='col-md-4 mr-3'>  
        <div className='profile-content'>
          <p className='my-profile'>Paynow Info</p>
          <hr />
          <p>Please upload your QR Code.</p>
          
            <div className='uploadBox'>
                <img src={plus} alt="" />
            </div>
          
          <input type="file" onChange={saveFile} className="btn change" />
          <button className='btn remove' >Remove Photo</button>
        </div> 
      </div>
      <div className='col'>
      <div className='form-submission'>
        <p className='my-profile'>Bank Info</p>
        <hr />
        <form> 
          <label>Full Name</label>   
          <input type="text" placeholder="Andre" onChange={handleChange} defaultValue={user.fullname} name="fullname" className='form-control' readOnly/>
        
          <label>Bank Name</label> 
          <input type="text" placeholder="Please enter bank name" onChange={handleChange} value={user.bankname} name="bankname" className='form-control' />
          <label>Account Type</label> 
          <input type="text" placeholder="E.g Account,Savings" onChange={handleChange} name="type" value={user.type} className='form-control' />
          <label>Account No.</label>      
          <input type="number" placeholder="Please enter account no." onChange={(e)=>handleChange(e)}  onWheel={(e) => { e.target.blur(e) }} name="number" value={user.number} className='form-control' autoComplete="off" />
          <div className='bottom'>
          <div className='buttons-left'>
            <button className='btn btn-success mr-3' onClick={paymentFunction} >Save</button>
            <button className='btn btn-cancel' onClick={()=>history.push('/')}>Cancel</button>
          </div>
          </div>
          </form>
      </div>
      </div>
      </div>
    </div>
  </div>
  <ToastContainer />
  </>
  )
}

export default Payment