/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import portfolioOne from "../../images/vendor/portfolioOne.png";
import portfolioTwo from "../../images/vendor/portfolioTwo.png";
import plus from "../../images/vendor/plus.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const portfolios = [
  { image: portfolioOne },
  { image: portfolioTwo },
  { image: portfolioOne },
  { image: portfolioTwo },
  { image: portfolioOne },
  { image: plus, classing: "uploadBox" },
];

function Portfolio({ setStatus }) {
  const [services, setservices] = useState([]);
  const [serviceImages, setServiceImages] = useState([])
  const [files, setfiles] = useState([]);
  const [files2, setfiles2] = useState([]);
  const [singleService, setsingleService] = useState("");
  const [userData, setUserData] = useState(null)

  const [loading, setLoading] = useState(false)

  const packageJSON = {
    '360 Virtual Tours': "vrpackage",
    'Videography': "videopackage",
    'Photography': "photographypackage",
    'Copywriting': "copywritingpackage",
    'Aerial Drones': "dronespackage",
    'Live Stream': "streampackage",
    'Funeral': "funeralpackage",
    'Wedding': "weddingpackage",
    'Birthday': "birthdaypackage",
    'Portrait': "potraitpackage"
  }

  const fetchData = async () => {

    var numberOfPages=1;

    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/services',
      headers: { }
    };
    
    axios(config)
    .then(function (response) {
      numberOfPages=response.data.meta.pagination.pageCount
    })
    .catch(function (error) {
      console.log(error);
    });

    var dataOfPortfolio=[]

    for(let i=1;i<=numberOfPages;i++)
    {
      const config2 = {
        method: 'get',
        url: 'https://api.trippincreatives.com/api/services?populate=%2A&pagination[page]='+i,
        headers: {}
      };
  
      await axios(config2)
        .then(function (response) {
         
          const vendor = JSON.parse(localStorage.getItem('user'))
          console.log(response.data.data.filter(u => u.attributes.userid === vendor.id && u.attributes.service_name === singleService),i)

          
          dataOfPortfolio=dataOfPortfolio.concat(response.data.data.filter(u => u.attributes.userid === vendor.id && u.attributes.service_name === singleService))
         
        
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    setServiceImages(dataOfPortfolio)
         
          toast.success(singleService + " Images Fetched", {
            position: "top-right",
            autoClose: 2000,
          })
  };


  useEffect(() => {
    const fetchProfileData = async () => {
      setfiles([])
      setfiles2([])
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config)
        console.log("Response from Profile Data", response.data)
        setUserData(response.data)
        setservices(response.data.services);
        if (singleService === "") {
          setsingleService(response.data.services[0])
        }
        await fetchData();
      } catch (error) {
        toast.error(error.response.data.error.message);
      }
    }
    fetchProfileData()
  }, [singleService])


  const onChange = (event) => {
    event.preventDefault();
    toast.success("Image Selected")
    console.log("The Target Files", event.target.files[0])
    setfiles2([URL.createObjectURL(event.target.files[0])]);
    setfiles([event.target.files[0]]);
  };

  const removeImage = (image) => {
    setfiles2((oldState) => oldState.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  };

  const deleteImage = async (id) => {
    var con = {
      method: 'delete',
      url: 'https://api.trippincreatives.com/api/services/' + id,
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };

    axios(con)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        toast.success('Your Image is deleted', {
          position: "top-right",
          autoClose: 2000,
        });

        window.location.reload(true);
        // setStatus(2);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Error");
      });
  }

  const uploadFiles = async () => {
    if (!userData.phone || userData.phone === "") {
      return toast.error("Add Contact Details in Profile to add Service Images")
    }

    if (files) {

      console.log("The files are", files)
      setLoading(true)
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const vendor = JSON.parse(localStorage.getItem('user'))

      await axios.post("https://api.trippincreatives.com/api/upload", formData).then(async (res) => {

        console.log('After Posting Image', res.data[0])

        toast.success("Image Posted Successfully")

        var dataOfUpload = JSON.stringify({
          "data": {
            "userid": vendor.id,
            "service_name": singleService,
            "images": [
              res.data[0].id
            ],
            "imageid": res.data[0].id
          }
        });
        var config2 = {
          method: 'post',
          url: 'https://api.trippincreatives.com/api/services',
          headers: {
            'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614',
            'Content-Type': 'application/json'
          },
          data: dataOfUpload
        };

        await axios(config2)
          .then(async function (response) {
            console.log("Services Uploaded Successfully", response.data);
            toast.success("Services Updated Successfully", {
              position: "top-right",
              autoClose: 2000,
            })
            setfiles([])
            setfiles2([])
            await fetchData()
            setLoading(false)
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false)
          });

      }).catch(async (e) => {
        console.log(e)
        toast.error(e.message+" or file size is too large")
        setfiles([])
        setfiles2([])
        await fetchData()
        setLoading(false)
      });
    }
    else {
      toast.error("No Files Selected")
      setLoading(false)
    }
  }

  let corporate = ["Videography", "360 Virtual Tours", "Photography", "Copywriting", "Aerial Drones", "Live Stream"]

  return (
    <>
      <div className="portfolio">
        <div className="container">
          <div className="row no-gutters">
            <div className='col-md-4'>
              {(services && services.filter(a => corporate.includes(a)).length > 0) && <p>Corporate</p>}
              <ul style={{ cursor: 'pointer' }}>
                {services && services.filter(a => corporate.includes(a)).map((s, i) => (
                  <li className={s === singleService ? "active" : ""} onClick={() => setsingleService(s)}>{s}</li>
                ))}
              </ul>
              {(services && services.filter(a => !corporate.includes(a)).length > 0) && <p>Personal</p>}
              <ul style={{ cursor: 'pointer' }}>
                {services && services.filter(a => !corporate.includes(a)).map((s, i) => (
                  <li className={s === singleService ? "active" : ""} onClick={() => setsingleService(s)}>{s}</li>
                ))}
              </ul>
            </div>
            <div className="col info">
              <p>My Services</p>
              <hr />
              <div className="row no-gutters">
                {serviceImages.length > 0 && serviceImages.map((f, i) => {


                  if (f.attributes.images.data !== null) {
                    return (
                      <div className="col-md-4 " key={i}>
                        <div className="mx-2">
                          <img
                            src={`https://api.trippincreatives.com${f.attributes.images.data[0].attributes.url}`}
                            alt=""
                            className="rounded w-100 img-fluid p-image"
                          />
                          <button className="button btn btn-danger overflow-hidden " onClick={() => deleteImage(f.id)}><RiDeleteBinLine /></button>

                        </div>
                      </div>
                    )
                  }
                }
                )
                }
                {files2 && files2.map((f, i) => (
                  <div className="col-md-4" key={i}>
                    <div className="mx-2">
                      <button className="button btn btn-danger" onClick={() => removeImage(f)}><RiDeleteBinLine /></button>

                      <img
                        src={f}
                        alt=""
                        className="rounded w-100 img-fluid p-image"
                      />
                    </div>

                  </div>
                ))
                }
                {!(files.length > 0 && files2.length > 0) && <div className="col-md-4">
                  <label for="profileImage" style={{ width: "100%" }}>
                    <a style={{ cursor: "pointer" }}>
                      <div className="uploadBox">
                        <img src={plus} alt="" className="img-fluid" />

                      </div>
                    </a>
                  </label>
                  <input

                    type="file"
                    name="profileImage "
                    onChange={onChange}
                    id="profileImage"
                    multiple
                    accept="image/png, image/jpeg, image/webp"
                    style={{ display: "none" }}
                  />

                </div>}
              </div>


              {(files.length > 0 && files2.length > 0) && <div className="d-flex">
                <button disabled={loading} className="btn btn-success mt-5 ml-2" style={{ height: 40 }} onClick={uploadFiles}>
                  {loading ? "Images are Uploading..." : "Save"}
                </button>

              </div>}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Portfolio;