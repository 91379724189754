/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Navbar from '../components/topNav'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

import profile from '../images/vector.png';

function Orders({ history }) {
  const [data, setdata] = useState([])

  const packageJSON = {
    "vrpackage": '360 Virtual Tours ',
    "videopackage": 'Videography',
    "photographypackage": 'Photography',
    "copywritingpackage": 'Copywriting',
    "dronespackage": 'Aerial Drones',
    "streampackage": 'Live Stream',
    "funeralpackage": 'Funeral',
    "weddingpackage": 'Wedding',
    "birthdaypackage": 'Birthday',
    "potraitpackage": 'Portrait'
  }
  


  const getOrders = async () => {
    const vendor = JSON.parse(localStorage.getItem('user'))
    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/orders?pagination[pageSize]=500&sort=createdAt%3Adesc',
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data)

        setdata(response.data.data.filter(u => u.attributes.customer_id === vendor.id))
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(() => {
    getOrders()
  }, [])
  const timeChanger = (value) => {
    let timeString = value;
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    let ampm = (H < 12 || H === 24) ? "AM" : "PM";
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString
  }
  console.log('data',data)
  return (
    <div className='orders row no-gutters'>
      {/* <ToastContainer /> */}

      <Navbar />
      <div className='container'>
        <h1>My Orders</h1>
        <table className="table table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Talent Name</th>
              <th scope="col">Service</th>
              <th scope="col">Date of Event</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((c, i) => (
              <tr style={{ cursor: 'pointer' }} onClick={() => history.push(`/orders/${c.id}`)}>
                <th scope="row" key={c.id}>{c.id}</th>
                <td>{c.attributes.vendor_name||"Name Not Available"}</td>
                <td>{packageJSON[c.attributes.service_name]}</td>
                <td>{c.attributes.date} , {timeChanger(c.attributes.time)}</td>
                <td className={c.attributes.status}>{c.attributes.status}</td>
                <td><img src={profile} alt="" /></td>
              </tr>
            ))}
            {data.length === 0 && <h3 style={{ textALign: "center" }}>No Data Available</h3>}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  )
}

export default Orders