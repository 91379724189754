import { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from '../../../src/images/logo.png';
import loginImg from '../../../src/images/login/login-img.png';
import eye from '../../../src/images/login/eye.png';
import craft from '../../../src/images/craft.png';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';



function ForgotPassword() {

  const [alert,setAlert] = useState();
  
  const initialValues = {
    email: ""
}

const validationSchema = Yup.object({
    email: Yup.string().email("Insert a valid email").required("Required")
});

const onSubmit = (values, { setSubmitting, resetForm }) => {
    setAlert();

    axios
        .post('https://api.trippincreatives.com/api/auth/forgot-password', values)
        .then(response => {
            const message = `Please check your email to reset your password.`;
            toast.success("Please check your email to reset your password.")
            setAlert(['success', message]);

            resetForm();
        })
        .catch(error => {
            if ( !error.response.data.message ) {
                setAlert(['alert', toast.error("Please Enter Valid email")])
            } else {
                const messages = error.response.data.message[0].messages;

                const list = [];
                messages.map((message,i) => {
                    let item = "";
                    if (i === 0) item += `<ul>`;
                    
                    item += `<li>${message.id}</li>`;

                    if (i === messages.length - 1) item += `</ul>`
                    list.push(item);
                });

                setAlert(['alert', list]);
            }
        })
        .finally(() => {
            setSubmitting(false);
        });
}


  return (
    <>
       <div className='login'>
      <ToastContainer />
      <img src={craft} alt="" className='craft' />
      <div className='left'>
        <Link className="navbar-brand py-4" to="/login">
          <img src={logo} alt="" />
        </Link>
        <img src={loginImg} alt="" />
      </div>
      <div className='center'>
        <div className='right ml-5'>
          <h1>Forgot password</h1>
          {/* <hr/>
          {alert && (
                <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }}>
                    <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
                </div>
            )}
        <br/> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm })} >
            { ({ isSubmitting, isValid }) => (
                <Form>
                    <div  className="">
                        <div ><label for="email">Email</label></div>
                        <Field  className='form-control' type="email" id="email" name="email" placeholder="Email" />
                        <div className="error" ><ErrorMessage  name="email" /></div>
                    </div>

                    <br />

                    <button 
                         className='btn'
                        type="submit"
                        disabled={!isValid} >
                            
                        {!isSubmitting && "Send link"}
                        {isSubmitting && "Loading..."}
                    </button>
                </Form>
            )}
        </Formik>
        </div>
      </div>
    </div>
       
        
    </>
)
}

export default ForgotPassword



