import React from 'react'
import { Rating } from '@mui/material';

import girl from '../../images/girl.png';
import ronnie from '../../images/ronnie.png';
import samantha from '../../images/samantha.png';
import tape from '../../images/tape.png';
import greenStar from '../../images/greenStar.png';
import greyStar from '../../images/greyStar.png';

function Testimonials(props) {

  console.log("props", props);
  const personaldata = props.testimonial.map((personaldata) => {
    console.log('personaldata',personaldata);
    return (
      <div className='card'>
        <img src={tape} alt="" className='tape' />
        <p className='mb-5'>{personaldata.Testimonial}</p>
        <div className='d-flex '>
          <img src={`https://api.trippincreatives.com${personaldata.OwnerImage.data.attributes.url}`} alt="" className='testimonialpic mr-4' />
          <div>
            <p className='mb-0'>{personaldata.Name}</p>
            <Rating name="read-only" value={personaldata.Rating} readOnly />
            <img src={personaldata.Rating} alt="" className='star' />
          </div>
        </div>
      </div>
    )
  })
  return (


    <div className='testimonials' >
      <div className='container'>
        <img src={girl} alt="" className='girl' />
        <h2 className='testimonial-heading'>Testimonials</h2>
        <div className='testimonial-cards'>
          {personaldata}
        </div>
      </div>
    </div >
  )
}

export default Testimonials