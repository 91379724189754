/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

function Charts({ earned, graphData }) {

  useEffect(() => {
    console.log("Earned from Charts", earned)

  }, [])

  const state = {
    labels: ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Earnings',
        fill: 'start',
        lineTension: 0,
        backgroundColor: '#e9840f7a',
        borderColor: '#E9840F',
        borderWidth: 2,
        data: graphData
      }
    ]
  }

  return (
    <div className='charts'>
      <div className='d-flex justify-content-between'>
        <div>
          <p>Bookings this month</p>
          <p className='desc'>Overview of for the year</p>
        </div>
        <select name="cars" id="cars">
          <option value="">2023</option>
        </select>
      </div>

      <Line
        data={state}
        options={{
          title: {
            display: true,
            text: 'Average Rainfall per month',
            fontSize: 20
          },
          legend: {
            display: true,
            position: 'right'
          }
        }}
      />
    </div>
  )
}

export default Charts