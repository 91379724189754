import React from 'react'
import { Link } from 'react-router-dom'
import search from '../../images/vendor/search.png';
import message from '../../images/vendor/message.png';
import bell from '../../images/vendor/bell.png';
import profileImage from '../../images/vendor/profileImage.png';
import arrow from '../../images/vendor/arrowDown.png';
import profilePic from '../../images/appointment/placeholder.jpeg';
import { useState, useEffect } from 'react';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';

function TopNav() {
  const [pic, setPic] = useState("")
  const [picture, setpicture] = useState("");


  const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
    // localStorage.removeItem();

  }


  useEffect(() => {
    // settoken(localStorage.getItem("token"));

    const fetchData = async () => {
      // Getting profile picture
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config);

        setpicture(response.data.image.formats.thumbnail.url);

        console.log(response.data.image.formats.thumbnail.url);
      } catch (error) {
        toast.error(error.response.data.error.message);
        // console.log(error)
      }
    };
    fetchData();
  }, []);

  return (
    <div className='container'>
      <div className='topnav'>
        <div className="input-group rounded">
          {/* <input type="search" className="form-control rounded searchBox" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
        <span className="input-group-text border-0 search-img" id="search-addon">
          <img src={search} alt="" />
        </span> */}
        </div>
        <div className='icons'>
          {/* <img src={message} alt="" />
          <img src={bell} alt="" /> */}
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {picture === "" ? <img className="rounded-circle z-depth-0" height="35" src={profilePic} /> : <img className="rounded-circle z-depth-0" height="35" src={`https://api.trippincreatives.com${picture && picture}`} alt="" />}
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link onClick={logout} className="dropdown-item">Logout</Link>
          </div>
          {/* <img src={arrow} alt="" /> */}
        </div>
      </div>
    </div>
  )
}

export default TopNav