import React from 'react'

import logo from '../../images/logo.png';
import next from '../../images/appointment/next.png';
import back from '../../images/appointment/back.png';

function StepSix({changeStatus,handleChange,signupFunction,handlePass,user}) {
  return (
    <div className='step-six'>
      <div className='container'>
      <img onClick={()=> changeStatus(4)} src={back} alt="" className='back' />  
        <img src={logo} alt="" />
        <h2>How should we keep in contact?</h2>
        <form>
        <input type="text" placeholder="Full Name" name="fullname" className='form-control' value={user.fullname}  onChange={handleChange} />
        <div className='d-flex'>
        <input type="text" placeholder="Enter Address" name="email" className='form-control mr-4' value={user.email} onChange={handleChange} />
        <input type="number" placeholder="Contact No" name="contact" className='form-control' value={user.contact} onChange={handleChange} />
        </div>
        <div className='d-flex align-items-center'>
        <input type="checkbox" className='form-control mr-2' />
        <p className='mb-4'>Create an account for me.</p>
        </div>
        <div className='d-flex'>
        <input type="password" placeholder="Password" onChange={handlePass} className='form-control mr-4' />
        <input type="password" placeholder="Confirm Password" className='form-control' />
        </div>
        </form>
        <button onClick={(e)=> {changeStatus(6);signupFunction(e)}} className='btn nxt'>Next</button>
      </div>
    </div>
  )
}

export default StepSix