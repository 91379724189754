import React, { useState } from 'react'

import camera from '../../images/camera.png';
import copywriting from '../../images/copywriting.png';
import drone from '../../images/drone.png';
import person from '../../images/person.png';
import studio from '../../images/studio.png';
import vr from '../../images/vr.png';
import arrowright from '../../images/arrow-right.png'
import service from '../../images/service.png'
import service_1 from '../../assets/services/service_1.jpg'
import service_2 from '../../assets/services/service_2.png'
import service_3 from '../../assets/services/service_3.jpg'
import service_4 from '../../assets/services/service_4.jpg'
import service_5 from '../../assets/services/service_5.jpg'



function Services(props) {

  //  console.log("props",props);
  return (
    <div className='services'>
      {/* <div className='container'> */}
      <div className=' ml-5'>
        <div className='heading'>
          <h1>Services</h1>
        </div>
        {/* <div className='container'> */}
        {/* */}


        <div className="container">

          <div className="row">
            <div className="gallery col">
              <div className="gallery__item--1 ">
                <div className="">
                  <p className="">Photography  </p>
                  <img className="arrow " src={arrowright}></img>

                </div>
                <img className="img  rounded" src={service_1} alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="gallery col">
              <div className="gallery__item--2 ">
                <div>
                  <p className="">Videography</p>
                  <img className="arrow " src={arrowright}></img>

                </div>

                <img className="img rounded " src={service_3} alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="gallery col">
              <div className="gallery__item--3 ">
                <div>
                  <p className="  ">360 Virtual Tours</p>
                  <img className="arrow " src={arrowright}></img>
                </div>
                <img className="img rounded " src={service_2} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="gallery col">
              <div className="gallery__item--4 ">
                <div>
                  <p className=" ">Aerial Drones</p>
                  <img className="arrow " src={arrowright}></img>
                </div>
                <img className="img rounded " src={service_4} alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="gallery col">
              <div className="gallery__item--5">
                <div>
                  <p className="">Live Stream</p>
                  <img className="arrow " src={arrowright}></img>
                </div>
                <img className="img rounded " src={service_5} alt="" />
              </div>
            </div>
          </div>

        </div>


      </div>
    </div >
    // </div>
    // </div>
  )
}

export default Services