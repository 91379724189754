import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
import logo from '../../../src/images/logo.png';
import loginImg from '../../../src/images/login/login-img.png';
import eye from '../../../src/images/login/eye.png';
import craft from '../../../src/images/craft.png';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';



function ResetPassword() {

    // const params = useParams();
    const location = useLocation();
    const [alert, setAlert] = useState();
    const history = useHistory();


    const initialValues = {
        password: "",
        passwordConfirmation: "",
    }

    const validationSchema = Yup.object({
        password: Yup.string().required("Required"),
        passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Required"),
    });

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setAlert();

        const qsearch = location.search;
        const code = new URLSearchParams(qsearch).get('code');
        console.log('code', code);
        values.code = code

        axios
            .post('https://api.trippincreatives.com/api/auth/reset-password', values)
            .then(response => {
                const message = `Your password has been resetted. In a few second you'll be redirected to login page.`;
                setAlert(['success', message]);
                toast.success("Your password has been resetted. In a few second you'll be redirected to login page.")

                resetForm();

                setTimeout(() => { history.push('/login') }, 5000);
            })
            .catch(error => {
                if (!error.response.data.message) {
                    setAlert(['alert', "Something went wrong"])
                } else {
                    const messages = error.response.data.message[0].messages;
                    toast.error("error.response.data.message[0].messages")
                    const list = [];
                    messages.map((message, i) => {
                        let item = "";
                        if (i === 0) item += `<ul>`;

                        item += `<li>${message.id}</li>`;

                        if (i === messages.length - 1) item += `</ul>`
                        list.push(item);
                    });

                    setAlert(['alert', list]);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <>
            <div className='login '>
                <ToastContainer />
                <img src={craft} alt="" className='craft' />
                <div className='left'>
                    <Link className="navbar-brand py-4" to="#">
                        <img className='' src={logo} alt="" />
                    </Link>
                    <img style={{ marginTop: '3rem' }} src={loginImg} alt="" />
                </div>
                <div className='center'>
                    <div className='right'>
                        <h1>Reset password</h1>
                        {/* <hr />
            {alert && (
                <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }}>
                    <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
                </div>
            )}
            <br /> */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm })} >
                            {({ isSubmitting, isValid }) => (
                                <Form>
                                    <div>
                                        <div><label for="password">Password</label></div>
                                        <Field className='form-control' type="password" id="password" name="password" placeholder="Password" />
                                        <div className="error"><ErrorMessage name="password" /></div>
                                    </div>

                                    <br />

                                    <div>
                                        <div><label for="passwordConfirmation">Repeat Password</label></div>
                                        <Field  className='form-control' type="password" id="passwordConfirmation" name="passwordConfirmation" placeholder="Repeat password" />
                                        <div className="error"><ErrorMessage name="passwordConfirmation" /></div>
                                    </div>

                                    <br />

                                    <button
                                     className='btn'
                                        type="submit"
                                        disabled={!isValid} >
                                        {!isSubmitting && "Reset password"}
                                        {isSubmitting && "Loading..."}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>





        </>
    );
}

export default ResetPassword 