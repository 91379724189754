/* eslint-disable no-undef */
import React from 'react'

import readMore from '../../images/read-more.png';
import readBtn from '../../images/read-btn.png';
import craft from '../../images/craft.png';
import dog from '../../images/dog.png';
import { useHistory } from 'react-router-dom';
import owner_1 from '../../assets/about/owner_1.png'
import owner from '../../assets/about/owner.png'

function ReadMore(props) {
  // console.log("props",props);
  const history = useHistory();

  const url = "https://api.trippincreatives.com";

  return (
    <div className='read w-100 mt-[500px] '>
      <div className='container'>
        <img src={craft} alt="" className='craft' />
        <div className='img-div'>
          {/* <img src={readMore} alt=""  className='w-100'/> */}
         <img className='owner'src={ owner } alt=""/>
          <img src={dog} alt="" className='dog '/>
          {/* <p style={{color: 'black', overflow: 'hidden', fontWeight: '700'}}>Jeremy Goh</p> */}
        </div>
        <div className='text-div '>
          <h2>{props.userdata.AboutHead}</h2>
          <p className=''>{props.userdata.AboutDesc}</p>
          {/* <p>We are a company dedicated to photography and digital design, with more than 5 years of experience, over which we have seen born and grow a digital world full of complex Professional techniques…</p> */}
          <div style={{ cursor: 'pointer' }} onClick={() => history.push('/about')}>
          <img  src={readBtn} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReadMore