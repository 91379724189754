/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Loader from '../components/appointment/Loader'
import StepEight from '../components/appointment/StepEight'
import StepFive from '../components/appointment/StepFive'
import StepOne from '../components/appointment/StepOne'
import StepSeven from '../components/appointment/StepSeven'
import StepSix from '../components/appointment/StepSix'
import StepThree from '../components/appointment/StepThree'
import StepTwo from '../components/appointment/StepTwo'
import ThanksYou from '../components/appointment/ThanksYou'
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51LPnqdEAdZ8vnOVA9ZJRlaTJHb6mS0y6w8Z7rr8EZUIAOmu2KZjb0Nw8IN9JL0NStaB4UpyL36va8hzeobUjxOVt00sHoIbR0C');

function Appointment() {
  const [user, setUser] = useState({
    portfolio_id: 0, service_name: "", date: "", time: "", duration: "", category: "corporate", customer_id: null,
    package: "", price: 0, gst: 0, quantity: 1, total_price: 0, fullname: "", email: "", contact: "", status: "Pending", vendor_id: null,vendor_name:null
  })
  const [packages, setPackage] = useState(null);
  const [status, setStatus] = useState(1);
  const [password, setpassword] = useState("")
  const [clientSecret, setClientSecret] = useState(null);
  const token = localStorage.getItem("token");
  const handlePass = (e) => {
    setpassword(e.target.value)
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const options = {
    clientSecret: clientSecret,
  };

  const getPaymentIntent = async () => {
    var axios = require('axios');

    var config = {
      method: 'get',
      url: 'https://api.trippincreatives.com/api/order/pretransaction',
      headers: {}
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setClientSecret(response.data.client_secret);
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(() => {
    getPaymentIntent()
    const registered = JSON.parse(localStorage.getItem("user"));
    // console.log(registered);
    if (token !== '' && registered !== null) {
      user.fullname = registered.fullname
      user.email = registered.email
      user.contact = registered.phone
    }
  }, [])



  const order = async () => {

    console.log(user);

    if (user.service_name === '' || user.date === '' || user.time === '' || user.duration === '' || user.category === '' || user.package === '' || user.fullname === '' || user.email === '' || user.contact === '') {
      return toast.error('All fields required')
    }
    try {
      const response = await axios.post('https://api.trippincreatives.com/api/orders', {
        data:
        {
          portfolio_id: user.portfolio_id,
          service_name: user.service_name,
          date: user.date,
          time: user.time,
          duration: user.duration,
          category: user.category,
          customer_id: user.customer_id,
          package: user.package,
          price: user.price,
          gst: user.gst,
          quantity: user.quantity,
          total_price: user.total_price,
          fullname: user.fullname,
          email: user.email,
          contact: user.contact,
          status: user.status,
          vendor_id: user.vendor_id,
          vendor_name:user.vendor_name
        }
      }, config)
      toast.info('Your Order Is Processing. Please Wait', {
        position: "top-right",
        autoClose: 2000,
        });
      user.service_name = '';
      user.date = '';
      user.time = '';
      user.duration = '';
      user.category = '';
      user.package = '';
      user.fullname = '';
      user.email = '';
      user.contact = '';
      //setStatus(8)
    }
    catch (e) {
      console.log(e)
      toast.error(e.response.data.error.message, {
        position: "top-right",
        autoClose: 2000,
        });

    }
  }
  // handle field change
  const handleChange = e => {
    console.log(e.target.name, e.target.value);
    if (e.target.name === "date") {
      var dayaftertomorrow = new Date();
      dayaftertomorrow.setDate(dayaftertomorrow.getDate() + 2);
      console.log(`${dayaftertomorrow.getFullYear()}-${dayaftertomorrow.getMonth() < 9 ? `0${dayaftertomorrow.getMonth() + 1}` : dayaftertomorrow.getMonth() + 1}-${dayaftertomorrow.getDate() <= 9 ? `0${dayaftertomorrow.getDate()}` : dayaftertomorrow.getDate()}`);
      if (e.target.value < `${dayaftertomorrow.getFullYear()}-${dayaftertomorrow.getMonth() < 9 ? `0${dayaftertomorrow.getMonth() + 1}` : dayaftertomorrow.getMonth() + 1}-${dayaftertomorrow.getDate() <= 9 ? `0${dayaftertomorrow.getDate()}` : dayaftertomorrow.getDate()}`) {
        toast.error('Please select a date after tomorrow', {
          position: "top-right",
          autoClose: 2000,
          });
      }
      else {
        setUser({ ...user, [e.target.name]: e.target.value })
      }
    }
    else {
      setUser({ ...user, [e.target.name]: e.target.value })
    }
  }

  const handleCat = name => {
    setUser({ ...user, category: name })
  }
  const handleVendor = id => {
    setUser({ ...user, vendor_id: id })
  }
  const handleCustomer = (id,name) => {
    console.log("The id of vendor is ", id)
    const customer = JSON.parse(localStorage.getItem('user'));
    console.log("The customer is", customer);
    let u = user;
    u.customer_id = customer.id;
    u.fullname = customer.fullname;
    u.email = customer.email;
    u.contact = customer.phone;
    u.vendor_id = id;
    u.vendor_name=name
    setUser(u)

    //setUser({ ...user, customer_id: customer.id, vendor_id: id })
    console.log("Value after customer and vendor", user)
    changeStatus(4)
  }
  const handlePackage = (packages, price, gst, total, packagess) => {
    console.log("User from Package Screen",user)
    console.log(packages, price, gst, total, packagess)
    setPackage(packagess)
    setUser({ ...user, package: packages, price: price, gst: gst, total_price: Number(price) + Number(gst) });
    changeStatus(6)
  }
  console.log(user)
  const changeStatus = (value, loadingTime = 10) => {
    // It checks for validation if all the fields are completed it goes to the next step
    if (value === 3 && (user.service_name === '' || user.date === '' || user.time === '' || user.duration === '')) {
      return toast.error('Please Fill the fields and go to the next step', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    // else if (value === 3 && (user.category === '')) {
    //   return toast.error('Please Fill the fields and go to the next step');
    // } 
    // else if (value === 4 && (user.customer_id === null)) {
    //   return toast.error('Please select one and go to the next step', {
    //     position: "top-right",
    //     autoClose: 2000,
    //     });
    // }
    setStatus(10)

    setTimeout(() => { setStatus(value) }, loadingTime)
  }
  const signupFunction = async (e) => {
    e.preventDefault()
    if (user.fullname === '' || user.email === '' || password === '') {
      return toast.error('All fields required', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    try {
      const response = await axios.post('https://api.trippincreatives.com/api/auth/local/register', {
        email: user.email,
        fullname: user.fullname,
        username: user.fullname,
        phone: user.contact,
        password: password
      });
      console.log('response1111',response);
      localStorage.setItem('token', response.data.jwt);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      // response.data.user.fullname === '' || response.data.user.phone === '0' ? history.push('/profile') : history.push('/')
    }
    catch (e) {
      toast.error(e.response.data.error.message, {
        position: "top-right",
        autoClose: 2000,
        });
      // console.log(e.response.data.error.message)
    }
  }
  if (clientSecret != null) {
    return (

      <Elements stripe={stripePromise} options={options}>
      <ToastContainer />
          {}
        <div className='appointment row no-gutters'>
          {status === 1 && <StepOne changeStatus={changeStatus} handleChange={handleChange} user={user} completed={20} />}
          {status === 2 && <StepTwo changeStatus={changeStatus} handleCat={handleCat} user={user} completed={40} />}
          {status === 3 && <StepThree changeStatus={changeStatus} handleCustomer={handleCustomer} user={user} completed={60} handleVendor={handleVendor} />}
          {status === 4 && <StepFive changeStatus={changeStatus} handlePackage={handlePackage} user={user} completed={80} />}
          {status === 5 && <StepSix changeStatus={changeStatus} handleChange={handleChange} user={user} signupFunction={signupFunction} handlePass={handlePass} />}
          {status === 6 && <StepSeven changeStatus={changeStatus} packages={packages} user={user} order={order} completed={100} />}
          {status === 7 && <StepEight changeStatus={changeStatus} />}
          {status === 8 && <ThanksYou order={order}/>}
        </div>
      </Elements>
    )
  }
}

export default Appointment