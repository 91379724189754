import React, { useState, useEffect } from 'react'
import Footer from '../footer'
import Navbar from '../topNav'

import blogDetail from '../../images/blog/blog-detail.png';
import next from '../../images/blog/next.png';
import prev from '../../images/blog/prev.png';
import axios from 'axios';
import moment from 'moment';
function Detail({match, history}) {
 
  const url = "https://api.trippincreatives.com";
  

  const [blog, setBlog] = useState(null)
  const [blogs, setBlogs] = useState([])
  const getBlog = async () => {
    try {
      const blogs = await axios.get(`https://api.trippincreatives.com/api/blogs/${match.params.id}?populate=%2A`);
      console.log(blogs)
      setBlog(blogs.data.data)
    }
    catch(e) {
      console.log("Some Error Occured Fetching Read More Data",e);
    }
  }
  useEffect(()=>{
    getBlog()
  },[match])
  const allblogs = async()=>{
    try {
      const blogsArray = await axios.get(`${url}/api/blogs?populate=image`);
      const array = blogsArray.data.data.map(ar =>{
        return {...ar, isTrue: false}})
      const filter = array.filter(ar => ar.id == match.params.id ? ar.isTrue = true : ar)
      setBlogs(filter)
    }
    catch(e) {
      console.log(e);
    }
  }
  useEffect(()=>{
    allblogs()
  },[])
  // const array =  connectionArray.map(conn => conn.ArrivalLocationCode === obj.ArrivalLocationCode ? { ...conn, check: e } : { ...conn } );

  const prevFun = (value) => {
    const filter = blogs.map(fil => value == fil.id ? {...fil, isTrue : true} : fil.isTrue === true ? {...fil, isTrue:false} : fil)
    setBlogs(filter);
    history.push(`/blog/${value}`)
    window.scroll(0,0)
  }
  const nextFunc = (value) =>{
    const filter = blogs.map(fil => value == fil.id ? {...fil, isTrue : true} : fil.isTrue === true ? {...fil, isTrue:false} : fil)
    setBlogs(filter);
    history.push(`/blog/${value}`)
    window.scroll(0,0)
  }
  return (
    <div className='blog row no-gutters'>
      <Navbar />
      <div className='detail'>
       {blog && <div className='container'>
          <p>Posted on {moment(blog.attributes.publishedAt).format('Do MMM YY')}</p>
          <h1>{blog.attributes.title}</h1>
          <img src={`${url}${blog.attributes.image.data.attributes.url}`} alt="" className='w-100' />
          <p className='mt-5'>{blog.attributes.description}</p>
          <div className='d-flex justify-content-between mt-5'>
           {blogs.map((blg, i )=> (blg.isTrue &&<> <button className='btn' disabled={i === 0} onClick={()=> prevFun(blogs[i - 1].id)}>
            <img src={prev} alt="" className='mr-3' />Previous</button>
            <button className='btn' disabled={i === blogs.length -1} onClick={()=> nextFunc(blogs[i  +1].id)}>Next<img src={next} alt="" className='ml-3'/></button></>))}
          </div>
        </div>}
      </div>
      <Footer />
    </div>
  )
}

export default Detail