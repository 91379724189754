import React from 'react'
import works from '../../images/works/works.png';
import one from '../../images/works/one.png';
import two from '../../images/works/two.png';
import three from '../../images/works/three.png';
import selectedWork from '../../images/works/selected-work.png';
import arrow from '../../images/works/arrow.png';
import arrowTwo from '../../images/works/arrow-two.png';

function MyWorks(props) {
  // console.log("props",props);
  let links = "";

  const videoLink = links.concat('https://www.youtube.com/embed/q0D_8kqwPE4?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&disablekb=1&rel=0&showinfo=0&color=black&playsinline=1&enablejsapi=1&playlist=ciK48oilHaI');
  return (
    <div className='works'>
    <div className='container'>
    <div className='row no-gutters mt-5'>
      <div className='col-md-6'>
        <p>Selected Works</p>
        <iframe className="video " src={videoLink} width="500px" height="650" frameborder="0" allowfullscreen></iframe>

      {/* <iframe className=" mt-2 " src="https://www.youtube.com/embed/q0D_8kqwPE4?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&disablekb=1&rel=0&playsinline=1&enablejsapi=1" width="500px" height="650" frameborder="0" allowfullscreen></iframe> */}

        </div>
        <div className='col-md-6 padd'>
        <h2>{props.userdata.SecondTitle}</h2>   
        <p>{props.userdata.SecondDesc}</p>
        <iframe className="video-two " src={videoLink} width="500px" height="650" frameborder="0" allowfullscreen></iframe>


       </div>
    </div>  
    <div className='row no-gutters mt-5'>
      <div className='col-md-6 padd'>
        <h2>{props.userdata.Number01}</h2>
        <h2>{props.userdata.Number01Title}</h2>
        <p>{props.userdata.Number01Desc}</p>
        <div className='d-flex arrow'><img src={arrow} alt=" " /></div>
        </div>
       <div className='col-md-6'>
        <img src={`https://api.trippincreatives.com${props.image.url}`} alt="" className='img-fluid'/>
       </div>
    </div>  
    <div className='row no-gutters mt-5'>
      <div className='col-md-6'>
        <img src={`https://api.trippincreatives.com${props.img.url}`} alt="" className='img-fluid'/>
       </div>  
      <div className='col-md-6 padd'>
        <h2>{props.userdata.Number02}</h2>
        <h2>{props.userdata.Number02Title}</h2>
        <p>{props.userdata.Number02Desc}</p>
        <div className='d-flex arrow'><img src={arrowTwo} alt=" " /></div>
        </div>
    </div> 
    <div className='row no-gutters mt-5'>
      <div className='col-md-6 padd'>
        <h2>{props.userdata.Number03}</h2>
        <h2>{props.userdata.Number03Title}</h2>
        <p>{props.userdata.Number03Desc}</p>
        </div>
       <div className='col-md-6'>
        <img src={`https://api.trippincreatives.com${props.pic.url}`} alt="" className='img-fluid'/>
       </div>
    </div>  
    </div>
  </div>
  )
}

export default MyWorks