import React from 'react'

import loader from '../../images/appointment/loader.png';
import dino from '../../images/appointment/dino.png';

function Loader() {
  return (
    <>
    <div className='loader'>
      <img src={loader} alt="" />
      <img src={dino} alt="" className='dino' />
      <p className='para'>Please wait while we are looking for talents..</p>
    </div>
    </>
  )
}

export default Loader