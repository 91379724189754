import React, { useState, useRef, useEffect } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import loginImg from '../images/login/login-img.png';
import eye from '../images/login/eye.png';
import eyeSlash from '../images/login/eye-slash.png';
import craft from '../images/craft.png';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

function Login({ history }) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [open, setOpen] = useState(false);
  const email = useRef();
  const password = useRef();


  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.trippincreatives.com/api/connect/google/callback${history.location.search}`)
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        if (!response.data.user.type) {
          setOpen(true)
          return console.log(response.data.user.type)

        }
        else {
          toast.success("User Logged In", {
            position: "top-right",
            autoClose: 2000,
            });
          if (response.data.user.type === 'customer') {
            response.data.user.fullname === '' || response.data.user.phone === '0' || response.data.user.phone === null ? history.push('/profile') : history.push('/')
          }
          else {
            history.push('/vendor')
          }
        }
      } catch (error) {
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 2000,
          });
      }
    }
    if (history.location.search) {
      fetchData()
    }
  }, [])

  const loginFunction = async (e) => {
    e.preventDefault()

    if (email.current.value === '' || password.current.value === '') {
      return toast.error('All fields required', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    if (!email.current.value.includes('@')) {
      return toast.error('please enter valid email address', {
        position: "top-right",
        autoClose: 2000,
        });
    }
    try {
      console.log(email.current.value, password.current.value)
      const response = await axios.post('https://api.trippincreatives.com/api/auth/local/', {
        identifier: email.current.value,
        password: password.current.value
      });
      console.log("Response.....", response.data)
      localStorage.setItem('token', response.data.jwt);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      if (response.data.user.type === 'customer') {
        console.log(response.data.user)
        response.data.user.fullname === '' || response.data.user.phone === '0' || response.data.user.phone === null ? history.push('/profile') : history.push('/')
      }
      else {
        history.push('/vendor')
      }
    }
    catch (e) {
      toast.error("Invalid Email or Password", {
        position: "top-right",
        autoClose: 2000,
        });
      console.log(e.response.data.error.message)
      console.log("Login Error check")
    }
  }
  const update = async (value) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    try {
      const user = JSON.parse(localStorage.getItem('user'))

      const response = await axios.put(`https://api.trippincreatives.com/users/${user.id}`, {
        type: value
      }, config)
      setOpen(false)
      localStorage.setItem('user', JSON.stringify(response.data));
      setTimeout(() => {
        if (response.data.type === 'customer') {
          console.log(response.data)
          response.data.fullname === null || response.data.phone === null ? history.push('/profile') : history.push('/')
        }
        else {
          console.log('venfor')
          history.push('/vendor')
        }
      }, 2000)

    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='login'>
      <ToastContainer />
      <Modal open={open} updates={update} onClose={() => setOpen(false)} center>
        <h5>Please Select Your type Vendor or Customer</h5>
        <button onClick={() => update('vendor')} className='btn vendor-btn'>Vendor</button>
        <button onClick={() => update('customer')} className='btn'>Customer</button>
      </Modal>
      <img src={craft} alt="" className='craft' />
      <div className='left'>
        <Link className="navbar-brand py-4" to="/">
          <img src={logo} alt="" />
        </Link>
        <img src={loginImg} alt="" />
      </div>
      <div className='center'>
        <div className='right'>
          <h1>Login</h1>
          <form>
            <div className="form-group">
              <label>Email</label>
              <input ref={email} type="email" className="form-control" placeholder="Enter email" />
            </div>
            <div className="form-group position-relative">
              <div className="">
              <label>Password</label>
              <Link to="/forgot-password"  className="forget">Forgot password?</Link>
              </div>
              <input ref={password} type={isRevealPwd ? "text" : "password"} className="form-control password-field" placeholder="Enter Password" />
              <img
                className='eye'
                src={!isRevealPwd ? eyeSlash : eye}
                onClick={() => setIsRevealPwd(prevState => !prevState)}
              />
            </div>
            <button onClick={loginFunction} className='btn'>Login</button>
            <div className='text-center my-4'>
              {/* <p>or continue with</p> */}
              {/* <div className='d-flex g-signin justify-content-center my-4'>

             
                <img style={{ cursor: 'pointer' }} src={google} alt="" onClick={() => window.location.href = `https://api.trippincreatives.com/api/connect/google`} />
                <img style={{ cursor: 'pointer' }} src={fb} alt="" onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/connect/facebook`} />
                
              </div> */}
              <p>Don’t have an account?</p>
              <Link to='/signup' className='green'>Sign Up Now</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login