import logo from './logo.svg';
import { Route,useState,useEffect, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import PortfolioDetails from './pages/PortfolioDetails';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Detail from './components/blog/Detail';
import Appointment from './pages/Appointment';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import ProtectedRoute from './ProtectedRoute';
import ProtectedRouteVendor from './ProtectedRouteVendor';
import Vendor from './pages/Vendor';
import ChangePassword from './pages/ChangePassword';
import VendorAdmin from './pages/VendorAdmin';
import ScrollTop from './scrollTop';
import footer from '../src/components/footer';
import ThanksYou from './components/appointment/ThanksYou';
import { Element } from 'react-scroll'
import axios from 'axios';
import { toast} from 'react-toastify';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';


function App() {
 
  return (
    <ToastContainer key="toasts" autoClose={3500} hideProgressBar />,
    <BrowserRouter>
      <ScrollTop />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/home" component={Home} exact />
        <Route path="/services/:name" component={Services} exact />

        <Route path="/portfolio" component={Portfolio} exact />
    
          <Route path="/login" component={Login} exact />
       
        <Route path="/signup" component={Signup} exact />
        <Route path="/details/:id" component={PortfolioDetails} exact />
        <Route path="/about" component={AboutUs} exact />
        <Route path="/blog" component={Blog} exact />
        <Route path="/blog/:id" component={Detail} exact />
        <Route path="/appointment" component={Appointment} exact />
        <Route path="/footer" component={footer} exact />
        <Route path="/vendorAdmin" component={VendorAdmin} exact />
        <Route path="/thankyou" component={ThanksYou} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/reset-password" component={ResetPassword} exact />

        <ProtectedRouteVendor path="/vendor" component={Vendor} exact />
        <ProtectedRouteVendor path="/change-password/vendor" component={ChangePassword} exact />
        <ProtectedRoute path="/change-password/customer" component={ChangePassword} exact />
        <ProtectedRoute path="/profile" component={Profile} exact />
        <ProtectedRoute path="/orders" component={Orders} exact />
        <ProtectedRoute path="/orders/:id" component={OrderDetails} exact />

      </Switch>
    </BrowserRouter>
  );
}

export default App;
