/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import profilePic from "../../images/appointment/placeholder.jpeg";
import lock from "../../images/profile/lock.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
const servicesOffer = [
  { name: "Videography" },
  { name: "360 Virtual Tours" },
  { name: "Photography" },
  { name: "Copywriting" },
  { name: "Aerial Drones" },
  { name: "Live Stream" },
];

const personalOffer = [
  { name: "Wedding" },
  { name: "Funeral" },
  { name: "Birthday" },
  { name: "Portrait" },
]


function Profile({ history, setStatus }) {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [profile, setprofile] = useState({
    fullname: "",
    email: "",
    phone: "",
    about: "",
    website: "",
  });
  const [picture, setpicture] = useState("");
  const [token, settoken] = useState("");
  const [file, setFile] = useState();
  const [imageId, setimageId] = useState(0);
  const [fileName, setFileName] = useState("");
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
  const [category, setCategory] = useState("Corporate");
  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  }

  const handleOnChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;

    console.log(`${value} is ${checked}`, languages);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        languages: languages ? [...languages, value] : [value],
        response: languages ? [...languages, value] : [value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };

  console.log('profile',profile)
  const handleChange = e => {
    setprofile({ ...profile, [e.target.name]: e.target.value })
  };
  useEffect(() => {
    settoken(localStorage.getItem("token"));

    const setData = async () => {
      // Getting data from the token
      try {
        if (user) {
          setprofile({ fileName: user.fullname })
          setprofile({ email: user.email })
          // profile.fullname = user.fullname;
          // profile.email = user.email;
          setprofile({ phone: user.phone })

          profile.phone = user.phone;
          // profile.about = user.aboutme;
          // profile.website = user.website;
        }
      } catch (error) {
        console.log(error);
      }
    };
    setData();

    const fetchData = async () => {
      // Getting profile picture
      setuser(JSON.parse(localStorage.getItem("user")))

      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        const response = await axios.get(`https://api.trippincreatives.com/api/users/id`, config);
        setUserInfo({
          languages: response.data.services,
          response: response.data.services,
        });
        setpicture(response.data.image.formats.thumbnail.url);
        setimageId(response.data.image.id);
        console.log(response.data.image.formats.thumbnail.url);
      } catch (error) {
        toast.error(error.response.data.error.message);
        // console.log(error)
      }
    };
    fetchData();
  }, []);
  const deleteImage = async (e) => {

    var con = {
      method: 'delete',
      url: 'https://api.trippincreatives.com/api/upload/files/' + imageId,
      headers: {
        'Authorization': 'Bearer 7b67643fa8aa526d77b4caf1a4b84f331aff28622e5331c3beaae036123210c7f0c25e0f1142cdd9afc93e5ad9a2e376d036db23bae3470f0b8ab3f349f0993fcb3caa316a6cb99531b38d076729135e2d2b2a7601586b9bac3e802d3a690968fab1260efd85634093a79e670eba4dd782e1b3966edf0933e5f89957c3953614'
      }
    };

    axios(con)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        toast.success('Your Image is deleted');
        setStatus(2);
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Error");
      });
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };



  const saveFile = (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    // if (!files) {
    //  toast.error('image is required');
    //  return false;
    //  }
    //  if (!files.name.match(/\.(jpg|jpeg|png)$/)) {
    //    toast.error('File does not support. You must use .png or .jpg!');
    //   //  window.location.reload(true);

    //   return false;
    //  }
    setStatus(2)
  }

  useEffect(() => {
    if (file) {
      order2();
    }
  }, [file, fileName]);
  const order = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("files", file);
    console.log(file);
    try {
      if (file) {
        // If we have a file
        const responses = await axios.put(
          `https://api.trippincreatives.com/api/users/${user.id}`,
          {
            fullname: profile.fullname,
            email: profile.email,
            phone: profile.phone,
            aboutme: profile.about,
            website: profile.website,
            services: userinfo.response,
          },
          config
        );
        localStorage.setItem("user", JSON.stringify(responses.data));
        console.log(responses);
        toast.success("Your Changes Are Saved");
        // history.push('/')
        setStatus(2);
        window.location.reload(false);

      } else {
        //  If there is no image
        const responses = await axios.put(
          `https://api.trippincreatives.com/api/users/${user.id}`,
          {
            fullname: profile.fullname,
            email: profile.email,
            phone: profile.phone,
            aboutme: profile.about,
            website: profile.website,
            services: userinfo.response,
          },
          config
        );
        localStorage.setItem("user", JSON.stringify(responses.data));
        console.log(responses);
        toast.success("Your Changes Are Saved");
        setStatus(2);
      }
    } catch (e) {
      toast.error(e.response.data.error.message);
    }
  };
  const order2 = async () => {
    let formData = new FormData();
    formData.append("files", file);
    console.log(file);
    try {
      if (!file) {
        toast.error('image is required', {
          position: "top-right",
          autoClose: 3000,

        });
        return false;
      }
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.error('File does not support. You must use .png or .jpg!', {
          position: "top-right",
          autoClose: 3000,

        });
        //  window.location.reload(true);

        return false;
      }
      if (file) {
        // If we have a file
        const response = await axios.post("https://api.trippincreatives.com/api/upload", formData);
        const responses = await axios.put(
          `https://api.trippincreatives.com/api/users/${user.id}`,
          {
            image: response.data[0].id,
          },
          config
        );
        localStorage.setItem("user", JSON.stringify(responses.data));
        console.log(responses);
        toast.success("Profile Picture uploaded", {
          position: "top-right",
          autoClose: 3000,

        });
        setStatus(2);
      }
      else {
        //  If there is no image
        const responses = await axios.put(
          `https://api.trippincreatives.com/api/users/${user.id}`,
          {
            fullname: profile.fullname,
            email: profile.email,
            phone: profile.phone,
            aboutme: profile.about,
            website: profile.website,
            services: userinfo.response,
          },
          config
        );
        localStorage.setItem("user", JSON.stringify(responses.data));
        console.log(responses);
        toast.success("Your Changes Are Saved", {
          position: "top-right",
          autoClose: 3000,

        });
        setStatus(2);
      }
    } catch (e) {
      toast.error(e.response.data.error.message);
    }
  };
  console.log('user', user);
  return (

    <div className="profile row no-gutters">
      <ToastContainer />
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-4 mr-3">
            <div className="profile-content">
              <p className="my-profile">My Profile</p>
              <hr />
              <img
                src={
                  picture
                    ? `https://api.trippincreatives.com${picture && picture
                    }`
                    : profilePic
                }
                alt=""
                className="profilePic"
              />
              <p className="mt-3">{user && user.fullname}</p>

              {/* {picture ? (
                ""
              ) : ( */}
              <input type="file" onChange={(e)=>saveFile(e)} className="btn change" />
              {/* )} */}
              <button className="btn remove" onClick={(e)=>deleteImage(e)}>
                Remove Photo
              </button>
            </div>
            <div className="profile-content mt-3">
              <p className="my-profile">My Services</p>
              <hr />
              <p>Please select the Category.</p>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div className="radio" style={{ display: "flex", flexDirection: "row", position: "relative", marginLeft: 25 }}>
                  <input
                    name='category'
                    type="radio"
                    value="Corporate"
                    checked={category === "Corporate"}
                    onChange={(e) =>handleChangeCategory(e)}
                  />
                  <label style={{ position: "absolute", left: 20 }}>
                    Corporate
                  </label>
                </div>
                <div className="radio" style={{ display: "flex", flexDirection: "row", marginLeft: 100, position: "relative" }}>
                  <input
                    name='category'
                    type="radio"
                    value="Personal"
                    checked={category === "Personal"}
                    onChange={(e)=>handleChangeCategory(e)}
                  />
                  <label style={{ position: "absolute", left: 20 }}>
                    Personal
                  </label>
                </div>
              </div>
              <p style={{ marginTop: 20 }}>Please select the services you provide.</p>
              {category === "Corporate" && servicesOffer.map((m, index) => (
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="languages"
                    value={m.name}
                    checked={userinfo.languages && userinfo?.languages.includes(m.name) ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <p className="ml-3">{m.name}</p>
                </div>
              ))}
              {category === "Personal" && personalOffer.map((m, index) => (
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="languages"
                    value={m.name}
                    checked={userinfo.languages && userinfo?.languages.includes(m.name) ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <p className="ml-3">{m.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <div className="form-submission">
              <p className="my-profile">Edit Profile</p>
              <hr />
              <form>
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter Full Name"
                  name="fullname"
                  defaultValue={user && user.fullname}
                  onChange={(e)=>handleChange(e)}
                  className="form-control"
                />

                <div className="d-flex">
                  <div className="w-100 mr-4">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Jessicakhoo@gmail.com"
                      name="email"
                      defaultValue={user && user.email}
                      onChange={(e) =>handleChange(e)}
                      className="form-control"
                    />
                  </div>
                  <div className="w-100">
                    <label>Contact No.</label>
                    <input
                      onWheel={(e) => { e.target.blur(e) }}
                      type="number"
                      placeholder="Contact No"
                      name="phone"
                      defaultValue={user && user.phone}
                      onChange={(e)=>handleChange(e)}
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <label>About Me</label>
                <textarea
                  type="text"
                  name="about"
                  placeholder="Write Something About Yourself"
                  className="form-control"
                  defaultValue={user && user.aboutme}
                  onChange={(e)=>handleChange(e)}
                  rows={5}
                />
                <label className="mt-4">My Website</label>
                <input
                  type="text"
                  placeholder="Website URL"
                  name="website"
                  defaultValue={user && user.website}
                  onChange={(e)=>handleChange(e)}
                  className="form-control"
                />

                <div className="bottom">
                  <div className="buttons-div">
                    <div className="d-flex align-items-center">
                      <img src={lock} alt="" />
                      <Link to="/change-password/vendor">
                        <p className="pass">Change Password</p>
                      </Link>
                    </div>
                    <button className="btn btn-success" onClick={(e) => order(e)}>
                      Save
                    </button>
                    <button
                      className="btn btn-cancel"
                      onClick={() => setStatus(1)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
