import React from 'react'
import owner_1 from '../../assets/about/owner_1.png'
import owner from '../../assets/about/owner.png'

import loginImg from '../../images/login/login-img.png';

function Hero(props) {
  console.log("props", props);
  return (
    <div className='hero'>
      <div className='container'>
      <div className='row no-gutters'>
        <div className='col-md-6'>
          <h1>{props.userdata.HeroTitle}</h1>
          <p className="bold">Photographs</p>
          <p className="bold">Videos</p>
          <p className="bold">Stories</p>
          <p>{props.userdata.HeroDesc}</p>
         </div>
         <div className='col-md-6 play mb-5'>
         <img className='owner' src={ owner } alt=""/>
          <img className='owner_1' src={ owner_1 } alt=""/>
         </div>
      </div>  
      </div>
    </div>
  )
}

export default Hero